@import "./colors";
@import "./constants";

.navbar#navbar-client-details {
    height: 15vh;
    flex-direction: column;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 3vw;

    .navbar-left,
    .navbar-right {
        display: flex;
        align-items: center;
    }

    .navbar-left {
        width: 65%;
    }

    .navbar-right {
        width: 35%;
    }

    .navbar-left {
        .back-button {
            border: 1px solid $darkPurple;
            border-radius: 5px;
            display: flex;
            padding: 10px 25px;
            align-content: center;
            align-items: center;

            .back-button-icon {
                margin-right: 10px;
            }

            .back-button-title {
                font-weight: bold;
                color: $darkPurple;
                font-size: 14px;
            }
        }

        .navbar-title-div {
            margin-left: 30px;
        }

        .styled-form {
            width: 50vw;
            display: flex;
            align-self: center;
            flex-direction: row;
            justify-content: space-between;

            .input {
                width: 30vw;
                padding: 10px 0;
                margin: 15px 0;
                color: $darkPurple;
                font-size: 20px;
            }

            .form-buttons {
                width: 10vw;
                display: flex;
                justify-content: space-around;

                .delete-icon-button {
                    color: $error;
                }

                .save-icon-button {
                    color: $success;
                }
            }
        }
    }

    .navbar-right {
        .navbar-actions-buttons-div {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            .action-button {
                display: flex;
                justify-content: center;
                align-content: center;
                padding: 10px 25px;

                .action-button-text {
                    color: $darkPurple;
                }

                .action-button-icon {
                    color: $darkPurple;
                    margin-left: 10px;
                }

                .action-button-text.delete-text,
                .action-button-icon.delete-icon {
                    color: $error;
                }
            }

            .delete-button {
                border: 1px solid $error;
                border-radius: 5px;
                display: flex;
                padding: 10px 25px;
                align-content: center;
                align-items: center;
            }
        }
    }
}

#client-details-main-div {
    top: 15vh;
    min-height: 85vh;
    flex-direction: column;
    padding: 25px 0;

    .section {
        padding: 20px 0 50px 0;
        margin-top: 20px;
        width: 100%;

        .section-header {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            border-bottom: 1px solid $darkGrey;
            padding-bottom: 10px;

            .section-header-text {
                color: $darkGrey;
                font-weight: bold;
            }
        }

        .section-content {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .no-data-text {
                width: 100%;
                margin-top: 20px;
                text-align: center;
                font-family: "Open Sans", sans-serif;
                color: $darkGrey;
                font-size: 16px;
            }
        }

        .card {
            &:hover {
                cursor: inherit;
                background-color: white;
            }

            .card-header {
                .small-delete-button-icon {
                    color: $error;
                }
            }
        }
    }
}

.loading-div {
    display: flex;
    justify-content: center;
    padding: 20px 0;
}

#resend-email-button {
    border-color: $orange;
    margin: 10px 0;

    .resend-email-button-text {
        text-transform: none;
        color: $orange;
    }
}

.delete-dialog {
    .close-button,
    .action-button {
        padding: 5px 15px;

        &:hover {
            transform: scale(1.05);
        }

        .button-text {
            color: white;
            font-family: "Open Sans";
            font-size: 16px;
        }
    }

    .close-button {
        background-color: $darkPurple;
    }

    .action-button {
        background-color: $error;
        margin-left: 10px;
    }
}
