@import "./colors";

.navbar {
    width: calc(100% - 250px);
    height: 10vh;
    min-height: 95px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 3vw;
    box-sizing: border-box;
    border-bottom: 1px solid $lightGrey;
    background-color: white;
    position: fixed;
    right: 0;
    left: calc(100vw - (100vw - 250px));
    z-index: 1;

    .navbar-top {
        min-height: 95px;
    }

    .navbar-bottom {
        min-height: 45px;
        background-color: white;
    }

    .navbar-title {
        font-size: 32px;
        font-weight: bolder;
        font-family: "Open Sans";
        color: $darkPurple;
    }

    .navbar-button-title {
        font-family: "Open Sans";
        font-weight: bold;
        font-size: 15px;
    }

    .navbar-button-icon {
        width: 20px;
        height: 20px;
        margin-left: 5px;
    }
}
