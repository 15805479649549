@import "./colors";

#navbar-entity-profiles {
    .action-button {
        width: 250px;
        display: flex;
        justify-content: center;
        align-content: center;
        padding: 10px 25px;

        .action-button-text.delete-text {
            color: $error;
        }
    }

    .delete-button {
        border-radius: 5px;
        display: flex;
        padding: 5px 25px;
        align-content: center;
        align-items: center;
    }
}

#entity-profile-edit {
    .entity-profile-form {
        display: flex;
        flex-direction: column;
        padding: 25px;
        background-color: white;
        border-radius: 5px;
        width: 45vw;
        min-width: 450px;

        .input {
            height: 50px;
            margin-top: 15px;
        }

        .select-form-control {
            margin-top: 15px;

            &:first-child {
                margin-top: 0;
            }
        }

        .center-loader {
            display: flex;
            justify-content: center;
        }
    }

    .submit-button {
        margin-top: 50px;
    }
}
