@import "./colors";

.link {
    text-decoration: none;
}

.sidebar-main-div {
    height: 100vh;
    background-color: $darkPurple;
    display: flex;
    flex-direction: column;
    width: 250px;
    overflow-y: hidden;

    .divider {
        background-color: $secondaryDarkPurple;
    }

    .logo-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 10vh;
        min-height: 95px;

        img {
            width: 80%;
            align-self: center;
            min-width: 210px;
        }
    }

    .main-div {
        height: calc(100% - 150px);
        padding-top: 1vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        overflow-y: auto;

        .link {
            display: flex;

            .link-div {
                min-width: 210px;
                padding: 15px 0;
                margin-top: 5px;
                display: flex;
                flex-direction: row;
                align-self: center;

                .link-icon {
                    font-size: 16px;
                    color: $orange;
                    margin-right: 15px;
                }

                .link-text {
                    color: white;
                    font-family: "Open Sans";
                    font-size: 16px;
                }
            }

            &.link-disabled {
                pointer-events: none;

                .link-div {
                    .link-icon {
                        color: $orangeDisabled;
                    }

                    .link-text {
                        color: $whiteDisabled;
                    }
                }
            }

            .support-link-div {
                min-width: 235px;
                padding: 15px 10px;
                margin-top: 5px;
                display: flex;
                flex-direction: row;
                background-color: $secondaryDarkPurple;
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
                border-radius: 5px;

                .support-link-icon {
                    font-size: 23px;
                    color: $success;
                    align-self: center;
                }

                .support-link-text {
                    color: white;
                    font-family: "Open Sans";
                    font-weight: bold;
                    font-size: 16px;
                }
            }
        }
    }

    .profile-div {
        height: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 25px;
        background-color: $secondaryDarkPurple;

        .user-data {
            display: flex;

            .icon-div {
                display: flex;
                justify-content: center;
                flex-direction: column;
                background-color: $darkPurple;
                padding: 10px;
                color: $orange;
            }

            .user-info-div {
                margin-left: 10px;

                .user-info-text {
                    color: white;
                    font-size: 18px;
                    font-family: "Open Sans";
                    font-weight: bold;
                }
            }
        }

        .logout-button {
            display: flex;
            justify-content: flex-start;
            margin-top: 10px;
            padding-left: 0;

            .logout-button-text {
                color: white;
                font-size: 11px;
                font-weight: 600;
                text-transform: capitalize;
            }

            .logout-button-icon {
                margin-left: 10px;
                color: $orange;
            }
        }
    }
}
