@import "./colors";

#subscriptions-list-main-div,
#subscriptions-add-main-div,
#subscription-details-main-div,
#project-add-main-div,
#projects-list-main-div,
#empty-projects-list-main-div,
#empty-clients-list-main-div,
#project-details-main-div,
#manual-add-main-div,
#resource-add-success-main-div,
#client-add-main-div,
#clients-list-main-div,
#client-details-main-div,
#notifications-list-main-div,
#empty-notifications-list-main-div,
#calendar-main-div,
#settings-main-div,
#support-main-div,
#ticket-success-main-div,
#delete-subscriptions-main-div,
#sync-google-calendar-main-div,
#subscription-plans-main-div,
#subscription-change-main-div,
#billing-main-div,
#subscription-confirm-main-div,
#subscription-change-success-main-div,
#ambassadors-main-div,
#become-ambassador-main-div,
#ambassador-code-activated-main-div,
#entities-edit-main-div,
#entity-profiles-main-div,
#entity-profile-edit,
#groups-main-div,
#groups-edit-main-div {
    position: absolute;
    top: 10vh;
    right: 0;
    left: 250px;
    width: calc(100% - 250px);
    padding: 25px 3vw;
    min-height: 90vh;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-start;
    background-color: $lightGrey;
}

.dropdown-icon {
    color: $darkGrey;
}

.error-div {
    width: 80%;
    margin: 0 auto;
    align-self: center;
    align-items: center;
    margin: 0 auto;

    .error-text {
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        text-align: center;
        color: $error;
    }
}

.phone-input-div {
    .phone-input-style {
        border-radius: 0;
        border: 0;
        border-bottom: 1px solid $lightGrey;
        width: 100%;
    }

    .phone-input-button-style {
        border-radius: 0;
        border: 1px solid $lightGrey;
        border-left: 0;
        border-top: 0;
        background-color: white;
        margin-right: 10px;
    }

    .intl-tel-input {
        width: 100%;
    }

    .intl-tel-input input,
    .intl-tel-input input[type="text"],
    .intl-tel-input input[type="tel"] {
        width: 100%;
        border: 0;
        border-bottom: 1px solid $darkGrey;
        padding: 10px 0;
        padding-left: 60px;
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
    }

    .intl-tel-input .country-list {
        background-color: white;
    }

    .intl-tel-input .flag-container .arrow {
        display: none;
    }

    .intl-tel-input .country-list .country .dial-code {
        color: $darkGrey !important;
    }

    .intl-tel-input.allow-dropdown .selected-flag {
        padding: 0 10px;
        border-right: 1px solid $darkGrey;
    }
}
