@import "./colors";

#default-permission-groups {
    display: flex;
    justify-content: space-between;
    border-bottom: 1.5px solid $disabledButtonBackground;
    padding-bottom: 15px;

    .group {
        width: 30%;
        padding: 5px;
        border: 1px solid $darkGrey;
        border-radius: 5px;

        .group-name {
            font-size: 14px;
            text-align: center;
            color: $darkPurple;
        }

        &:hover {
            cursor: pointer;
            background-color: rgba($success, $alpha: 0.5);
            border: none;

            .group-name {
                color: white;
            }
        }

        &.selected {
            background-color: $success;
            border: 1px solid $success;

            .group-name {
                color: white;
                font-weight: bolder;
            }
        }
    }
}

#permissions-select {
    width: 100%;
    margin-top: 10px;

    .resource-label {
        width: 90px;
        font-size: 12px;
        align-self: center;
        color: $darkPurple;
    }

    .row {
        display: flex;
        margin-bottom: 1px;

        .permissions-header {
            display: flex;
            width: 100%;

            .permission {
                display: flex;
                justify-content: center;
                border: none;
                width: calc((100% - 25px) * 0.25);
                min-width: 65px;

                &:hover {
                    cursor: initial;
                    background-color: white;
                }

                .permission-text {
                    font-size: 12px;
                    text-align: center;
                    align-self: center;
                    color: $darkPurple;
                }
            }
        }

        .permissions {
            width: 100%;
            display: flex;
        }

        .permission {
            width: 25%;
            min-width: 65px;
            height: 35px;
            border: 1px solid $darkGrey;
            border-radius: 5px;
            transition: 0.3s;
            margin-right: 10px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                cursor: pointer;
                background-color: rgba($success, $alpha: 0.5);
            }

            &.selected {
                border: 1px solid $success;
                background-color: $success !important;
            }

            &.disabled {
                border-color: $darkGrey !important;
                background-color: $disabledButtonBackground !important;

                &:hover {
                    cursor: initial;
                }
            }
        }
    }
}
