@import "./colors";

.reset-main-div {
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: $darkPurple;

    .left,
    .right {
        width: 50vw;
        height: 100vh;
    }

    .left {
        display: flex;
        justify-content: center;
        background-color: $secondaryDarkPurple;

        .left-main-div {
            width: 25vw;
            align-self: center;

            .page-title {
                font-weight: bold;
                font-size: 30px;
                color: white;
            }

            .styled-form {
                padding: 50px 0;
                display: flex;
                flex-direction: column;

                .password-input {
                    margin: 10px 0;
                    color: white;
                    border-bottom: 2px solid $lightPurple;
                }

                .password-changed-text {
                    text-align: center;
                    font-weight: 500;
                    color: $success;
                    margin-top: 30px;
                }
            }

            .back-button {
                display: flex;
                flex-direction: row;
                text-decoration: none;
                color: $orange;

                .icon-div {
                    display: flex;
                    justify-content: center;
                    border: 1px solid $orange;
                    margin-right: 15px;
                }

                .back-button-text {
                    align-self: center;
                    font-family: "Open Sans", sans-serif;
                    font-weight: 500;
                }
            }
        }
    }

    .right {
        background-size: contain;
        background-repeat: repeat;
        background-position: center;
        background-image: url("../images/arrows_background_large.svg");
    }
}

@media only screen and (max-width: 992px) {
    .reset-main-div {
        .left {
            .left-main-div {
                width: 35vw;

                .page-title {
                    font-size: 30px;
                }

                .styled-form {
                    padding: 30px 0;

                    .password-input {
                        margin: 10px 0;
                    }

                    .password-changed-text {
                        margin-top: 25px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .reset-main-div {
        .left,
        .right {
            width: 100vw;
            min-width: 0;
        }

        .left {
            height: 80vh;

            .left-main-div {
                width: 90vw;

                .styled-form {
                    padding: 50px 0;
                }
            }
        }

        .right {
            height: 20vh;
            background-position: top;
            background-size: contain;
            background-repeat: repeat-y;
            background-image: url("../images/arrows_background_row.svg");
        }
    }
}
