@import "./colors";
@import "./navbar";
@import "./card";
@import "./constants";

.navbar#navbar-delete-subscriptions {
    width: 100vw;
    height: 15vh;
    flex-direction: column;
    left: 0;

    .navbar-top {
        width: 100vw;
        height: 10vh;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 3vw;
        border-bottom: 1px solid $lightGrey;
    }

    .navbar-bottom {
        width: 100vw;
        height: 5vh;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 3vw;

        .tab {
            font-family: "Open Sans", sans-serif;
        }
    }
}

#delete-subscriptions-main-div {
    top: 15vh;
    left: 0;
    width: 100vw;
    padding: 25px 3vw;
    min-height: 85vh;
    justify-content: flex-start;
    flex-wrap: wrap;

    .card {
        width: 23%;
        margin: 15px 1%;

        &:hover {
            cursor: initial;
            background-color: white;
        }

        &:nth-child(3n + 2) {
            margin-left: 1%;
            margin-right: 1%;
        }

        .card-header {
            border-bottom: 0;
            padding: 20px;
        }

        .card-content {
            margin-top: 0;

            .card-content-child {
                padding: 5px 15px;
                margin: 2px 0;
                display: flex;

                .title {
                    font-family: "Open Sans";
                    color: $darkGrey;
                    font-size: 16px;
                    margin-right: 5px;
                }

                .subtitle {
                    color: $darkPurple;
                    font-family: "Open Sans";
                    font-size: 16px;
                    font-weight: 600;
                }

                &.buttons-div {
                    flex-direction: column;
                    padding: 5px 0;

                    .delete-button,
                    .archive-button {
                        .button-text {
                            text-transform: none;
                        }
                    }

                    .delete-button {
                        border-color: $orange;

                        .button-text {
                            color: $orange;
                        }
                    }

                    .archive-button {
                        margin-top: 5px;
                        border-color: $darkPurple;

                        .button-text {
                            color: $darkPurple;
                        }
                    }
                }
            }
        }
    }
}

.actions-dialog {
    .buttons-div {
        .close-button,
        .action-button {
            padding: 5px 15px;

            &:hover {
                transform: scale(1.05);
            }

            .button-text {
                color: white;
                font-family: "Open Sans";
                font-size: 16px;
            }
        }

        .close-button {
            background-color: $darkPurple;
        }

        .action-button {
            background-color: $error;
        }
    }
}
