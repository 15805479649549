@import "./colors";

#navbar-entities-edit {
    .navbar-title {
        font-size: 23px;
    }

    .back-button {
        border-radius: 5px;
        display: flex;
        padding: 10px 25px;
        align-content: center;
        align-items: center;
        background-color: white;

        .back-button-icon {
            margin-right: 10px;
        }

        .back-button-title {
            font-weight: bold;
            color: $darkPurple;
            font-size: 14px;
        }
    }
}

#entities-edit-main-div {
}
