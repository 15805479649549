@import "./colors";
@import "./constants";

.navbar#navbar-project-details {
    height: 15vh;
    flex-direction: column;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 3vw;

    .navbar-left,
    .navbar-right {
        width: 50%;
        display: flex;
        align-items: center;
    }

    .navbar-left {
        .back-button {
            border: 1px solid $darkPurple;
            border-radius: 5px;
            display: flex;
            padding: 10px 25px;
            align-content: center;
            align-items: center;

            .back-button-icon {
                margin-right: 10px;
            }

            .back-button-title {
                font-weight: bold;
                color: $darkPurple;
                font-size: 14px;
            }
        }

        .navbar-title-div {
            margin-left: 30px;
        }

        .status-div {
            display: flex;
            align-items: center;

            .status-text {
                color: $darkGrey;
                margin-right: 5px;
            }

            .active-div {
                display: flex;
                align-items: center;

                .green-circle {
                    background-color: $success;
                    border-radius: 50%;
                    height: 10px;
                    width: 10px;
                }

                .active-text {
                    margin-left: 5px;
                    color: $success;
                    font-weight: 500;
                }
            }
        }

        .styled-form {
            width: 50vw;
            display: flex;
            align-self: center;
            flex-direction: row;
            justify-content: space-between;

            .input {
                width: 30vw;
                padding: 10px 0;
                margin: 15px 0;
                color: $darkPurple;
                font-size: 20px;
            }

            .form-buttons {
                width: 10vw;
                display: flex;
                justify-content: space-around;

                .delete-icon-button {
                    color: $error;
                }

                .save-icon-button {
                    color: $success;
                }
            }
        }
    }

    .navbar-right {
        justify-content: space-between;

        .navbar-actions-buttons-div {
            width: 65%;
            display: flex;
            justify-content: space-between;

            div:nth-child(2) {
                border-left: 1px solid $darkGrey;
                border-right: 1px solid $darkGrey;
                border-radius: 0;
            }

            .action-button {
                width: 33.3%;
                display: flex;
                justify-content: center;
                align-content: center;

                .action-button-text {
                    color: $darkPurple;
                }
                .action-button-icon {
                    color: $darkPurple;
                    margin-left: 10px;
                    min-width: 16px;
                }

                .action-button-text.delete-text,
                .action-button-icon.delete-icon {
                    color: $error;
                }
            }
        }

        .navbar-main-button-div {
            width: 30%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .navbar-main-button {
                background-color: $darkPurple;
            }
        }

        .navbar-button-icon {
            margin-left: 10px;
            min-height: 16px;
        }
    }
}

#project-details-main-div {
    top: 15vh;
    min-height: 85vh;
    flex-direction: column;
    padding: 25px 0;

    > div:not(:last-child) {
        border-bottom: 1px dashed $darkGrey;
    }

    .section {
        padding: 20px 0 50px 0;
        margin-top: 20px;
        width: 100%;

        .section-header {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            padding-bottom: 10px;

            &.bordered {
                border-bottom: 1px solid $darkGrey;
            }

            .section-header-text {
                color: $darkGrey;
                font-weight: bold;
            }

            .section-title {
                color: $darkPurple;
                font-weight: bold;
                font-size: 20px;
            }
        }

        .section-content {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .no-data-text {
                width: 100%;
                margin-top: 5px;
                text-align: center;
                font-family: "Open Sans", sans-serif;
                color: $darkGrey;
                font-size: 16px;
            }
        }

        .card {
            &:hover {
                cursor: inherit;
                background-color: white;
            }

            .card-header {
                border-bottom: 0;
                padding: 15px 25px;

                .small-delete-button-icon {
                    color: $error;
                }
            }

            .card-content {
                .card-content-child {
                    .title {
                        width: 20%;
                        font-size: 14px;
                    }

                    .subtitle {
                        width: 80%;
                        font-size: 14px;
                        font-weight: bold;
                    }
                }

                .syncing-text {
                    font-size: 16px;
                    text-align: center;
                    color: $darkGrey;
                }
            }
        }
    }

    .info-section {
        .card {
            .card-header {
                border-bottom: 1px solid $lightGrey;

                .card-title {
                    font-size: 1.2rem;
                }

                .left {
                    width: 90%;
                }

                .delete-button-container {
                    width: 10%;

                    .small-delete-button-icon {
                        color: $error;
                    }
                }
            }

            .card-content {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                height: 100%;

                .card-content-child {
                    align-items: flex-start;
                    display: flex;
                    flex-direction: row;

                    .title {
                        width: 25%;
                        font-size: 14px;
                    }

                    .subtitle {
                        width: 75%;
                        font-size: 14px;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .parties-section {
        .section-content {
            .card {
                .card-header {
                    align-items: flex-start;

                    .name-div {
                        width: calc(100% - 20px);
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                    }

                    .card-subtitle {
                        color: $darkGrey;
                        font-size: 14px;
                    }

                    .card-title {
                        width: 100%;
                        overflow: auto;
                        white-space: normal;
                    }
                }

                .card-content {
                    .card-content-child {
                        .title {
                            font-size: 14px;
                        }

                        .subtitle {
                            font-size: 14px;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }

    .appeals-section {
        .section-content {
            .card {
                .card-header {
                    align-items: flex-start;

                    .left {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        .appeal-type-div {
                            display: flex;
                            justify-content: center;
                            width: auto;
                            margin-top: 5px;
                            padding: 3px 10px;
                            background-color: $lightGrey;

                            .appeal-type-subtitle {
                                font-size: 14px;
                                color: $darkGrey;
                            }

                            .appeal-type-title {
                                font-size: 14px;
                                color: $darkPurple;
                                font-weight: bold;
                                margin-left: 5px;
                            }
                        }
                    }
                }

                .card-content {
                    .card-content-child {
                        flex-direction: column;
                        background-color: white;
                        padding: 5px 10px;
                    }
                }
            }
        }
    }

    .hearings-section {
        .section-content {
            flex-direction: column;

            .card {
                width: 100%;
                border-radius: 3px;
                margin: 5px 0;
                flex-direction: row;
                justify-content: flex-start;

                &:nth-child(3n + 2) {
                    margin-left: 0;
                    margin-right: 0;
                }

                .hearings-card-left {
                    width: 30%;
                    border-right: 1px solid $lightGrey;
                    display: flex;
                    flex-direction: column;

                    .card-header {
                        padding: 15px 25px;

                        .right {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;

                            .label {
                                border: 1px solid $orange;
                                border-radius: 5px;
                                padding: 1px 10px;

                                .label-text {
                                    text-transform: uppercase;
                                    color: $orange;
                                    font-size: 12px;
                                }

                                &.phase-label {
                                    margin-bottom: 2px;
                                    background-color: $darkPurple;
                                    border: none;

                                    .label-text {
                                        color: white;
                                    }
                                }
                            }
                        }
                    }

                    .card-content {
                        width: 90%;
                        margin-top: 0px;
                        align-self: center;

                        .card-content-child {
                            padding: 5px 15px;
                            margin: 2px 0;
                            display: flex;
                            justify-content: space-between;

                            &:nth-child(odd) {
                                background-color: $lightGrey;
                            }

                            .title-div {
                                width: 40%;

                                .title {
                                    font-family: "Open Sans";
                                    color: $darkGrey;
                                    font-size: 16px;
                                    margin-right: 5px;
                                }
                            }

                            .subtitle-div {
                                width: 60%;

                                .subtitle {
                                    color: $darkPurple;
                                    font-family: "Open Sans";
                                    font-size: 16px;
                                    font-weight: 600;
                                }
                            }
                        }

                        .hearing-document-child {
                            flex-direction: column;

                            .title-div {
                                width: 100%;

                                .title {
                                    width: 100%;
                                }
                            }

                            .subtitle-div {
                                width: 100%;
                                display: flex;
                                justify-content: center;

                                .subtitle {
                                    width: 95%;
                                }
                            }
                        }
                    }
                }

                .hearings-card-right {
                    width: 70%;
                    padding: 15px 25px;

                    .header {
                        display: flex;
                        justify-content: flex-end;

                        .small-delete-button-icon {
                            color: $error;
                        }
                    }

                    .card-content-child {
                        display: flex;
                        flex-direction: column;

                        .title-div {
                            .title {
                                font-family: "Open Sans";
                                color: $darkPurple;
                                font-size: 16px;
                                font-weight: bold;
                            }
                        }
                        .subtitle-div {
                            margin-top: 20px;

                            .subtitle {
                                font-family: "Open Sans";
                                color: $darkGrey;
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }

    .parties-section,
    .appeals-section,
    .hearings-section {
        .edit-div {
            .edit-icon {
                color: $darkPurple;
            }
        }
    }
}

.delete-subscription-modal {
    width: 30vw;
    height: 60vh;
    display: flex;
    margin: 20vh auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;

    .buttons-div {
        margin-top: 100px;
        display: flex;
        justify-content: space-around;
        width: 100%;
    }
}

.archive-project-dialog,
.delete-subscription-dialog,
.delete-project-dialog,
.delete-client-dialog {
    .buttons-div {
        .close-button,
        .action-button {
            padding: 5px 15px;

            &:hover {
                transform: scale(1.05);
            }

            .button-text {
                color: white;
                font-family: "Open Sans";
                font-size: 16px;
            }
        }

        .close-button {
            background-color: $darkPurple;
        }

        .action-button {
            background-color: $error;
        }
    }
}

.sync-no-subscription-dialog {
    .buttons-div {
        .close-button,
        .action-link {
            padding: 5px 15px;
            border-radius: 5px;

            &:hover {
                transform: scale(1.05);
            }

            .button-text,
            .link-text {
                color: white;
                font-family: "Open Sans";
                font-size: 16px;
            }
        }

        .close-button {
            background-color: $darkPurple;
        }

        .action-link {
            background-color: $success;
            text-decoration: none;
        }
    }
}
