@import "./colors";

.refresh-component {
    width: 30vw;
    height: 65vh;
    display: flex;
    margin: 20vh auto;
    justify-content: center;
    background-color: white;

    .main-div {
        width: 95%;
        height: 95%;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        margin-top: 2.5%;
        justify-content: flex-start;

        .header {
            display: flex;
            justify-content: flex-end;

            .close-modal-button {
                padding: 5px;
                color: white;
                border-radius: 0;
                background-color: $orange;
            }
        }

        .content {
            width: 80%;
            height: 80%;
            margin-top: 15%;
            display: flex;
            align-self: center;
            flex-direction: column;

            .content-text-div {
                text-align: center;

                .modal-title {
                    font-size: 30px;
                    font-weight: bold;
                    color: $darkPurple;
                }

                .modal-text {
                    font-size: 18px;
                    margin-top: 10px;
                    color: $lightPurple;
                }
            }

            .styled-form-control {
                width: 100%;
                margin-top: 20px;
            }

            .buttons-div {
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                align-content: center;

                .button {
                    &:nth-child(2) {
                        margin-top: 10px;
                    }

                    &.outlined {
                        .button-text {
                            color: $orange;
                            text-transform: none;
                            border-color: $orange;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .refresh-component {
        width: 45vw;
    }
}

@media only screen and (max-width: 992px) {
    .refresh-component {
        width: 55vw;
    }
}
