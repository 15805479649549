@import "./colors";

.not-found-main-div {
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    background-color: $darkPurple;
    justify-content: space-between;

    .logo-div {
        width: 40vw;
        align-self: center;
    }

    .left,
    .right {
        width: 50vw;
        height: 100vh;
        min-width: 384px;
    }

    .left {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .message-div {
            width: 40vw;
            margin-top: 10vh;
            align-self: center;

            .message {
                width: 30vw;
                color: white;
                font-size: 50px;
                line-height: 1.2;
                font-weight: bold;

                span {
                    color: $orange;
                }
            }

            .go-back-text {
                font-size: 19px;
                color: $lightPurple;
                font-weight: lighter;
                margin: 20px 10px 0 0;
            }
        }

        .back-button {
            text-decoration: none;
            display: flex;
            flex-direction: row;
            justify-content: center;
            background-color: $orange;
            color: white;
            padding: 20px;
            margin-top: 25px;
            width: 70%;

            .icon-div {
                margin-right: 15px;
                display: flex;
                justify-content: center;
            }

            .back-button-text {
                font-family: "Open Sans", sans-serif;
                font-weight: bold;
            }
        }
    }

    .right {
        background-size: contain;
        background-repeat: repeat;
        background-position: center;
        background-image: url("../images/arrows_background_large.svg");
    }
}
