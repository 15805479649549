@import "./colors";

.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    padding: 15vh;

    .logo-div {
        margin-bottom: 70px;
    }

    .wait-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;

        .wait-text {
            margin-bottom: 25px;
        }
    }

    .back-button {
        text-decoration: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: $orange;
        color: white;
        padding: 20px;
        margin-top: 25px;
        width: 300px;

        .icon-div {
            margin-right: 15px;
            display: flex;
            justify-content: center;
        }

        .back-button-text {
            font-family: "Open Sans", sans-serif;
            font-weight: bold;
        }
    }

    .account-activation {
        max-width: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .activation-message {
            margin: 20px 0;
            text-align: center;
        }

        a {
            width: 300px;
            padding: 20px;
            margin-top: 25px;
            border-radius: 5px;
            background-color: $orange;
            text-decoration: none;
            text-align: center;
            color: white;
        }
    }
}

@media only screen and (max-width: 768px) {
    .main-container {
        padding: 50px 0;

        .logo-div {
            margin-bottom: 20px;
        }

        .circular-progress {
            width: 5px;
            margin-top: 25px;
        }

        .wait-text {
            font-size: 16px;
        }
    }
}
