@import "./colors";

.navbar#navbar-settings {
    border-bottom: 0px;
    background-color: $lightGrey;
    z-index: 2;
}

#settings-main-div {
    flex-direction: column;

    .delete-account-section {
        width: 250px;
        margin-top: 30px;

        a.advocatus-app-link {
            text-decoration: none;

            .delete-account-button {
                background-color: $error;
                border-color: $error;
            }
        }
    }

    .card-large {
        background-color: white;
        width: 100%;
        margin-top: 30px;

        &:first-child {
            margin-top: 0;
        }

        .center-loader {
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 15px 0;
        }

        .card-main-div {
            display: flex;
            flex-direction: column;

            .card-header {
                border-bottom: 1px dashed $lightGrey;
                padding: 20px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                align-content: center;

                .card-header-title {
                    font-size: 22px;
                    color: $darkPurple;
                    font-family: "Open Sans", sans-serif;
                    font-weight: bold;
                    margin-left: 10px;
                }

                .card-header-title-div {
                    display: flex;

                    .icon-div {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .icon {
                            color: $orange;
                            font-size: 30px;
                        }
                    }
                }

                .card-header-button {
                    align-self: flex-end;

                    .card-header-button-text {
                        font-family: "Open Sans", sans-serif;
                        font-weight: bold;
                        font-size: 14px;
                    }
                }
            }

            .card-body {
                width: 100%;
                align-self: center;
                padding: 50px 20px;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                .card-body-element {
                    width: 23%;

                    .input {
                        padding: 10px 0;
                        margin: 15px 0;
                        color: white;
                    }
                }
            }
        }

        .card-main-div#notifications-settings-card {
            background-color: white;
            width: 100%;

            .card-body {
                padding: 25px 20px;

                .card-body-element {
                    width: 33%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    align-content: center;

                    &:nth-child(2) {
                        border: 1px dashed $lightGrey;
                        border-top: 0;
                        border-bottom: 0;
                    }

                    .notifications-settings-sm-div {
                        width: 90%;
                        height: 100%;
                        padding: 10px 0;
                        align-self: center;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        .email-notifications-div,
                        .push-notifications-div {
                            height: 50%;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .notification-type-text {
                                font-family: "Open Sans", sans-serif;
                                font-size: 18px;
                                color: $darkPurple;
                            }
                        }

                        .email-notifications-div {
                            border-bottom: 1px dashed $lightGrey;
                        }

                        .notifications-settings-title-div {
                            .notifications-settings-title-text {
                                font-family: "Open Sans", sans-serif;
                                font-weight: bold;
                                font-size: 18px;
                                color: $darkPurple;
                                margin-bottom: 15px;
                            }
                        }

                        .notifications-settings-interval-div {
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                        }
                        .notifications-settings-times-div {
                            display: flex;
                            justify-content: space-between;

                            .time-input {
                                width: 48%;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-end;

                                .input {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }

        .card-main-div#project-settings-card {
            background-color: white;
            width: 100%;

            .card-body {
                padding: 25px 20px;

                .info-text {
                    font-size: 16px;
                    color: $darkGrey;
                }

                .card-body-element {
                    width: 33%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    &:nth-child(2) {
                        width: 67%;
                        border-left: 1px dashed $lightGrey;
                        padding-left: 25px;
                    }

                    .switch-container {
                        height: 50%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .switch-text {
                            font-size: 18px;
                            color: $darkPurple;
                        }
                    }

                    .interval-picker {
                        .interval-picker-text {
                            font-weight: bold;
                            font-size: 18px;
                            color: $darkPurple;
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }

        .card-main-div#calendar-settings-card {
            .card-body {
                .card-header-secondary {
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 30px;

                    .texts-div {
                        margin-left: 20px;

                        .cal-sync-primary-text {
                            font-family: "Open Sans", sans-serif;
                            font-weight: bold;
                            font-size: 20px;
                            color: $darkPurple;
                        }

                        .cal-sync-secondary-text {
                            font-family: "Open Sans", sans-serif;
                            font-size: 16px;
                            color: $darkGrey;
                        }
                    }
                }

                .sync-button {
                    padding-top: 3px;
                    padding-bottom: 3px;
                    padding-left: 3px;
                    background-color: #4285f4;

                    .sync-button-div {
                        width: 100%;
                        display: flex;
                        flex-direction: row;

                        .google-icon-div {
                            background-color: white;
                            height: 55px;
                            width: 55px;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            img {
                                height: 60%;
                            }
                        }

                        .google-sync-text {
                            align-self: center;
                            margin-left: 15px;
                        }
                    }
                }

                .sync-other-calendars-button {
                    padding-top: 3px;
                    padding-bottom: 3px;

                    .sync-button {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        background-color: inherit;
                        height: 55px;
                        align-items: center;
                    }
                }

                .left {
                    width: 45%;

                    .google-sync-info-div {
                        display: flex;
                        flex-direction: row;
                        margin-bottom: 30px;

                        .left-sm {
                            width: 12%;
                            min-width: 55px;
                            border-right: 1px dashed $lightGrey;
                        }

                        .right-sm {
                            width: 88%;
                            padding-left: 25px;

                            .title {
                                font-family: "Open Sans", sans-serif;
                                font-weight: bold;
                                font-size: 20px;
                                color: $darkPurple;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                margin-bottom: 10px;

                                .sync-check {
                                    color: $success;
                                    height: 22px;
                                    margin-right: 5px;
                                }
                            }

                            .info-text {
                                font-family: "Open Sans", sans-serif;
                                font-size: 16px;
                                color: $darkGrey;
                            }
                        }
                    }
                }

                .right {
                    width: 45%;

                    .ical-input-div {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        .copy-button {
                            width: 35px;
                            height: 35px;
                            background-color: $darkPurple;
                            border-radius: 5px;

                            .copy-button-icon {
                                color: white;
                            }
                        }

                        .input {
                            width: calc(100% - 40px);
                        }
                    }
                }
            }
        }

        .card-main-div#my-subscription-settings-card {
            .card-body {
                width: 100%;
                padding: 30px;
                justify-content: space-between;
                flex-wrap: wrap;

                .cards-container {
                    display: flex;
                    flex-wrap: wrap;
                    width: calc(100% + 20px);
                    margin: 0 -10px;

                    .card-body-element {
                        width: calc(16.66% - 20px);
                        margin: 10px;
                        min-width: 150px;
                        background-color: white;
                        border-radius: 3px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        text-align: center;
                        border: 1px solid $lightGrey;
                        padding: 15px;

                        .title {
                            font-size: 14px;
                            color: $darkPurple;
                            line-height: 2;
                        }

                        .value {
                            font-size: 20px;
                            font-weight: bold;
                            color: $orange;

                            span.subscription-limit {
                                display: block;
                                font-weight: normal;
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }

        .card-main-div#company-data-settings-card {
            .card-body {
                width: 100%;
                padding: 30px;
                justify-content: space-between;
                flex-wrap: wrap;

                .entity-data {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    width: calc(100% + 20px);
                    margin: 0 -10px;

                    .col {
                        width: calc(33.33% - 20px);
                        margin: 10px;
                        min-width: 200px;

                        .row {
                            display: flex;

                            .title {
                                color: $darkPurple;
                            }

                            .value {
                                font-weight: bold;
                                margin-left: 5px;
                                color: $darkPurple;
                            }
                        }
                    }

                    &.person {
                        .col {
                            width: calc(50% - 20px);
                        }
                    }

                    @media only screen and (max-width: 1000px) {
                        .col {
                            width: calc(50% - 20px);
                        }
                    }

                    @media only screen and (max-width: 780px) {
                        .col {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .card-main-div#company-users-settings-card {
            > .card-header {
                padding: 0 20px !important;

                .navbar-left {
                    width: 70%;
                    display: flex;
                    justify-content: space-between;
                    padding: 20px;
                    padding-left: 0;
                    border-right: 1px dashed $lightGrey;
                }

                .navbar-right {
                    width: 30%;
                    padding: 20px 0;

                    .card-header-title {
                        text-align: center;
                    }
                }

                @media only screen and (max-width: 975px) {
                    .navbar-left {
                        flex-direction: column;

                        .card-header-button {
                            margin-top: 20px;
                        }
                    }
                }
            }

            .card-body {
                padding: 0 20px !important;

                .cards-container {
                    display: flex;
                    flex-wrap: wrap;
                    width: calc(100% + 20px);
                    margin: 0 -10px;

                    .card {
                        min-width: 200px;
                        width: calc(33% - 20px);
                        background-color: #f7f7fc;

                        &:hover {
                            cursor: initial;
                        }

                        .card-header {
                            padding: 12px 25px;
                            border-bottom: 1px solid $lightGrey;

                            .small-delete-button-icon {
                                color: $error;
                            }
                        }

                        .card-content {
                            .card-content-child {
                                .subtitle {
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }
                        }
                    }
                }

                .left {
                    width: 70%;
                    padding: 30px 10px;
                    border-right: 1px dashed $lightGrey;

                    .edit-entity-profile-button {
                        width: 25px;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;

                        .edit-entity-profile-icon {
                            color: $darkGrey;
                        }
                    }
                }

                .right {
                    width: 30%;
                    padding: 30px 10px;

                    .cards-container {
                        .card {
                            width: 100%;

                            .card-header {
                                border: none;
                                padding: 8px 25px;

                                .card-title {
                                    width: 85%;
                                    hyphens: auto;
                                    overflow: visible;
                                    word-wrap: break-word;
                                    white-space: pre-wrap;
                                    text-overflow: initial;
                                    overflow-wrap: break-word;

                                    span {
                                        font-size: 14px;
                                        font-weight: normal;
                                    }
                                }

                                .edit-group-button {
                                    width: 25px;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    margin-right: 10px;

                                    .edit-group-icon {
                                        color: $darkGrey;
                                    }
                                }
                            }
                        }

                        a.add-group {
                            margin-top: 30px;
                            background-color: $lightGrey;
                            text-decoration: none;

                            &:hover {
                                cursor: pointer;
                                transition: all 0.4s ease-in-out;
                                transform: scale(1.05);
                            }

                            .card-header {
                                padding: 5px;

                                .card-title {
                                    width: 100%;
                                    text-align: center;
                                    font-weight: bold;
                                    font-size: 16px;
                                }
                            }
                        }
                    }

                    .no-groups {
                        width: 100%;
                        font-size: 14px;
                        color: $darkGrey;
                        text-align: center;
                    }
                }
            }

            @media only screen and (max-width: 1680px) {
                .card-body {
                    .left {
                        .cards-container {
                            .card {
                                width: calc(33% - 20px);
                            }
                        }
                    }
                }
            }

            @media only screen and (max-width: 1380px) {
                .card-body {
                    .left {
                        .cards-container {
                            .card {
                                width: calc(50% - 20px);
                            }
                        }
                    }
                }
            }

            @media only screen and (max-width: 1110px) {
                .card-body {
                    .left {
                        .cards-container {
                            .card {
                                width: calc(100% - 20px);
                            }
                        }
                    }
                }
            }
        }
    }
}

.unsync-dialog {
    .dialog-header {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        padding: 10px;

        .close-dialog-button {
            border-color: $orange;

            .close-dialog-button-text {
                text-transform: none;
                color: $orange;
            }
        }
    }

    .dialog-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .dialog-unsync-button,
    .dialog-delete-button {
        &:hover {
            transform: scale(1.05);
            background-color: initial;
        }

        .button-text {
            color: white;
            font-family: "Open Sans";
            font-size: 16px;
        }
    }

    .dialog-unsync-button {
        background-color: $darkPurple;
        margin-bottom: 20px;

        &:hover {
            transform: scale(1.05);
            background-color: $darkPurple;
        }
    }

    .dialog-delete-button {
        background-color: $error;
        margin-left: 10px;

        &:hover {
            transform: scale(1.05);
            background-color: $error;
        }
    }
}

@media only screen and (max-width: 1400px) {
    #settings-main-div {
        .card-large {
            .card-main-div#my-subscription-settings-card {
                .card-body {
                    .cards-container {
                        .card-body-element {
                            width: calc(33% - 20px);

                            .title {
                                font-size: 16px;
                            }

                            .value {
                                font-size: 22px;
                            }
                        }
                    }
                }
            }

            .card-main-div#notifications-settings-card {
                .card-body {
                    padding: 0;
                    .card-body-element {
                        width: 100%;
                        margin-top: 15px;

                        &:nth-child(1) {
                            order: 1;
                        }

                        &:nth-child(2) {
                            order: 3;
                            border: 0;
                        }

                        &:nth-child(3) {
                            order: 2;
                            padding: 15px 0;
                            border-top: 1px dashed $lightGrey;
                            border-bottom: 1px dashed $lightGrey;
                        }

                        .notifications-settings-sm-div {
                            .email-notifications-div {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

.delete-entity-profiles-dialog,
.delete-group-dialog {
    .buttons-div {
        .close-button,
        .action-button {
            padding: 5px 15px;

            &:hover {
                transform: scale(1.05);
            }

            .button-text {
                color: white;
                font-family: "Open Sans";
                font-size: 16px;
            }
        }

        .close-button {
            background-color: $darkPurple;
        }

        .action-button {
            background-color: $error;
        }
    }
}

@media only screen and (max-width: 1100px) {
    #settings-main-div,
    #users-main-div {
        .card-large {
            .card-main-div {
                .card-body {
                    padding: 30px 20px;

                    .card-body-element {
                        width: 48%;
                        margin-top: 10px;

                        &:nth-child(1) {
                            order: 4;
                        }

                        &:nth-child(2) {
                            order: 1;
                        }

                        &:nth-child(3) {
                            order: 2;
                        }

                        &:nth-child(4) {
                            order: 3;
                        }
                    }
                }
            }

            .card-main-div#calendar-settings-card {
                .card-body {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .left,
                    .right {
                        width: 100%;
                        align-self: center;
                    }

                    .left {
                        padding-bottom: 35px;
                        border-bottom: 1px dashed $lightGrey;
                    }

                    .right {
                        margin-top: 35px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    #settings-main-div {
        .card-large {
            .card-main-div#my-subscription-settings-card {
                .card-body {
                    .cards-container {
                        .card-body-element {
                            width: calc(50% - 20px);
                        }
                    }
                }
            }
        }
    }
}
