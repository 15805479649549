@import "./colors";

.register-main-div {
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: $darkPurple;

    .left,
    .right {
        width: 50vw;
        height: 100vh;
    }

    .left {
        display: flex;
        justify-content: center;
        background-color: $secondaryDarkPurple;

        .left-main-div {
            width: 25vw;
            display: flex;
            flex-direction: column;
            align-self: center;

            .header-div {
                display: flex;
                flex-direction: column;
                align-items: center;
                align-self: center;
                margin-bottom: 40px;

                img {
                    max-width: 100%;
                }

                .create-account-text {
                    margin-top: 30px;

                    .header-title {
                        color: white;
                        text-align: center;
                        font-size: 1.5rem;

                        span {
                            color: $orange;
                        }
                    }

                    .header-helper {
                        margin-top: 10px;
                        color: $lightGrey;
                        font-size: 14px;
                        text-align: center;
                    }
                }
            }

            .error-div {
                margin: 20px 0;
            }

            .styled-form {
                display: flex;
                flex-direction: column;
                padding-bottom: 50px;

                .input {
                    margin: 10px 0;
                    color: white;
                    border-bottom: 2px solid $lightPurple;
                }

                .phone-input-div {
                    .phone-input-style {
                        border-bottom: 0;
                    }

                    .phone-input-button-style {
                        border-bottom: 0;
                    }

                    .intl-tel-input {
                        width: 100%;
                    }

                    .intl-tel-input input,
                    .intl-tel-input input[type="text"],
                    .intl-tel-input input[type="tel"] {
                        color: white;
                        background-color: transparent;
                        border-bottom: 0;
                    }

                    .intl-tel-input .flag-container .arrow {
                        display: none;
                    }

                    .intl-tel-input .country-list .country .dial-code {
                        color: $darkGrey !important;
                    }

                    .intl-tel-input.allow-dropdown .selected-flag {
                        padding: 0 10px;
                        border-right: 1px solid $darkGrey;
                    }
                }

                .terms-checkbox {
                    display: flex;
                    align-items: center;
                    padding: 5px 0 25px 0;

                    .terms-checkbox-text {
                        color: $darkGrey;
                    }

                    .terms-link {
                        color: white;
                        text-decoration: none;
                        margin-left: 5px;
                    }
                }
            }

            .back-button {
                display: flex;
                flex-direction: row;
                text-decoration: none;
                color: $orange;

                .icon-div {
                    display: flex;
                    justify-content: center;
                    border: 1px solid $orange;
                    margin-right: 15px;
                }

                .back-button-text {
                    align-self: center;
                }
            }
        }
    }

    .right {
        .promo-main-div {
            .promo-bottom-div {
                padding-bottom: 0;
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .register-main-div {
        .left {
            .left-main-div {
                width: 35vw;

                .styled-form {
                    padding: 30px 0;

                    .input {
                        margin: 10px 0;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .register-main-div {
        .left,
        .right {
            width: 100vw;
            min-width: 0;
        }

        .left {
            height: 100vh;

            .left-main-div {
                width: 90vw;

                .styled-form {
                    padding: 50px 0;
                }
            }
        }

        .right {
            display: none;
        }
    }
}
