@import "./colors";

.navbar#navbar-ambassadors {
    .navbar-top {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

div#ambassadors-main-div {
    padding: 70px 3vw;

    .container {
        width: 100%;
        height: calc(90vh - 140px);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .left {
            width: calc(35% - 5px);
            height: 100%;
            padding: 0 5px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow-y: auto;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }

            .promote-div {
                .promote-text {
                    font-size: 20px;
                    line-height: 1.3;
                    color: $darkPurple;
                    font-weight: 600;
                }

                .legaltech-text {
                    margin-top: 8px;
                    line-height: 1.2;
                    font-size: 16px;
                    color: $darkGrey;
                }
            }

            .benefits-div {
                margin-top: 10px;

                .title {
                    width: 80%;
                    font-size: 22px;
                    color: $darkPurple;
                    font-weight: bold;
                    text-transform: uppercase;
                    line-height: 1;
                }

                .benefit-div {
                    border-bottom: 1px solid #c8c8db;
                    margin-top: 15px;
                    padding: 10px 0;

                    .benefit-header {
                        display: flex;

                        .target-icon {
                            color: $orange;
                            margin-right: 10px;
                        }

                        .benefit-target {
                            color: $darkPurple;
                            font-weight: bold;
                        }
                    }

                    .benefit-text {
                        padding: 5px 0;
                        color: $darkPurple;
                    }
                }
            }

            .explanations-div {
                margin-top: 30px;

                .title {
                    width: 80%;
                    font-size: 22px;
                    color: $darkPurple;
                    font-weight: bold;
                    text-transform: uppercase;
                    line-height: 1;
                }

                .explanation-div {
                    padding: 10px 0;

                    .explanation-text {
                        padding: 5px 0;
                        color: $darkPurple;
                    }
                }
            }
        }

        .right {
            width: calc(65% - 5px);
            height: 100%;
            padding: 0 15px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .header {
                height: 100px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                border-bottom: 1px solid $darkGrey;
                padding: 30px;

                .header-title {
                    width: 90%;
                    color: $darkPurple;
                    text-transform: uppercase;
                    font-weight: bold;
                }
            }

            .content {
                height: calc(100% - 100px);
                padding: 30px;
                overflow-y: auto;
            }

            .ambassador-code-div {
                width: calc(60% - 10px);
                background-color: white;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .code-div {
                    height: calc(100% - 60px);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $lightGrey;

                    .code-text {
                        color: $darkGrey;
                        font-size: 32px;
                    }
                }

                .button-div {
                    height: 60px;
                    padding-top: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;

                    .copy-link-button {
                        height: 100%;

                        .icon {
                            color: white;
                            margin-right: 10px;
                        }
                    }
                }
            }

            .persons-div {
                width: calc(40% - 10px);
                background-color: white;

                .person-div {
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 1px solid $lightGrey;
                    padding-bottom: 8px;

                    &:not(:first-child) {
                        margin-top: 10px;
                    }

                    .person-info-div {
                        .person-name {
                            font-size: 18px;
                            font-weight: bold;
                            color: $darkPurple;
                        }

                        .person-email {
                            font-size: 13px;
                            color: $darkPurple;
                        }
                    }

                    .date-div {
                        .date {
                            font-size: 12px;
                            color: $darkGrey;
                            font-style: italic;
                        }
                    }
                }
            }
        }

        .loading-div {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}
