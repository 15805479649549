@import "colors";

#navbar-manual-add {
    .navbar-right {
        display: flex;

        .delete-button {
            background-color: $error;

            .button-title {
                color: white;
            }
        }

        .cancel-button {
            border: 1px solid $darkPurple;
            border-radius: 5px;
            display: flex;
            padding: 10px 25px;
            align-content: center;
            align-items: center;
            margin-left: 10px;
        }

        .button-title {
            font-weight: bold;
            color: $darkPurple;
            font-size: 14px;
        }
    }
}

div#manual-add-main-div {
    display: flex;
    flex-direction: column;
    align-content: center;

    .form {
        width: 70vw;
        max-width: 600px;

        .section {
            width: 100%;
            background-color: white;
            margin: 10px 0;
            padding-bottom: 20px;

            .section-content {
                padding: 10px 35px 20px 35px;

                .input-form-control {
                    width: 100%;
                    margin: 12px 0;

                    .test-label {
                        background-color: green;
                        z-index: 100;
                        margin-bottom: 10px;
                    }
                }

                .submit-button {
                    margin-top: 50px;
                }

                .disclaimer {
                    margin-top: 25px;
                    color: $darkGrey;
                    font-size: 0.8rem;
                }
            }
        }

        .buttons-div {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .link {
                display: flex;
                align-items: flex-start;
                margin-top: 1rem;
                padding: 5px 20px;
                border: 1px solid $darkPurple;
                border-radius: 5px;

                .link-text {
                    margin-right: 5px;
                    color: $darkPurple;
                }

                .link-icon {
                    color: $darkPurple;
                }
            }
        }
    }

    .time-input {
        margin: 12px 0;
        width: 100%;
    }

    .date-select {
        border-bottom: 1px solid $darkGrey;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        margin: 12px 0;

        .date-picker {
            width: 100%;
        }
    }
}

.test-div {
    position: relative;
    background-color: green;
    z-index: 10;

    > * {
        background-color: red;
        z-index: 100;
    }
}
