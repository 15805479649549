@import "./colors";

.subsctiption-is-syncing-modal {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
        width: 40vw;
        min-width: 300px;
        background-color: white;
        align-self: center;
        padding: 40px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .syncing-text,
        .sync-fail-text {
            font-size: 20px;
            color: $darkPurple;
            font-weight: bold;
            margin-bottom: 30px;
            text-align: center;
        }

        .button {
        }
    }
}
