.rbc-toolbar {
    width: 85vw;
    height: 10vh;
    padding: 0 3vw;
    position: fixed;
    left: calc(100vw - (100vw - 250px));
    margin-bottom: 50px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid $lightGrey;
    font-size: 18px;

    .title-and-loader-div {
        width: 33%;
        display: flex;
        align-items: center;

        .page-title {
            font-size: 32px;
            font-weight: bolder;
            font-family: "Open Sans";
            color: $darkPurple;
            margin-right: 20px;
        }
    }

    .rbc-toolbar-label {
        width: 33%;
        padding: 0;
        text-align: center;
        align-self: center;
        text-transform: capitalize;
        font-family: "Open Sans", sans-serif;
        font-weight: bold;
        color: $orange;
        font-size: 20px;
    }

    .rbc-btn-group {
        width: 33%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        button {
            // width: 20%;
            // padding: 10px;
            font-family: "Open Sans", sans-serif;
            color: $darkPurple;
            font-size: 14px;
        }
    }
}
