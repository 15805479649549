@import "./colors";

#entities-edit-form {
    margin: 0 auto;
    flex-direction: column;
    align-items: center;

    .tabs-div {
        width: 70vw;
        max-width: 600px;
        height: 45px;
        background-color: white;
        margin: 0 auto;
        margin-bottom: 10px;
        align-self: center;

        .tabs {
            width: 70vw;
            max-width: 600px;
        }

        .tabRoot {
            width: 50%;
            height: 45px;
        }

        .tab-label-div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .icon {
                align-self: center;
                margin-right: 5px;
                height: 20px;
            }
        }
    }

    .form {
        width: 70vw;
        max-width: 600px;

        .section {
            width: 100%;
            background-color: white;
            margin: 10px 0;
            padding-bottom: 20px;

            .section-header {
                border-bottom: 1px solid $lightGrey;
                padding: 15px 35px;

                .section-title {
                    text-transform: uppercase;
                    font-size: 18px;
                    font-weight: bold;
                    color: $darkPurple;
                }
            }

            .section-content {
                padding: 10px 35px 20px 35px;

                .input-form-control {
                    width: 100%;
                    margin: 12px 0;

                    .vat-number-div {
                        display: flex;
                        justify-content: space-between;

                        .search-company-button {
                            .button-title {
                                font-size: 15px;
                            }

                            .button-icon {
                                margin-left: 10px;
                            }
                        }
                    }
                }

                .phone-input-div {
                    margin-top: 10px;
                }

                .submit-button {
                    margin-top: 50px;
                }
            }
        }
    }
}
