@import "./constants";
@import "./colors";

.navbar#navbar-clients-add {
    .navbar-left {
        display: flex;
        align-items: center;
        width: 80%;

        .navbar-title {
            font-size: 20px;
            width: 15%;
        }

        .navbar-stepper {
            margin-left: 30px;
            width: 85%;
            padding: 0 15px;
            border-left: 1px solid $lightGrey;
        }
    }

    .cancel-button {
        border: 1px solid $darkPurple;
        border-radius: 5px;
        display: flex;
        padding: 10px 25px;
        align-content: center;
        align-items: center;

        .cancel-button-title {
            font-weight: bold;
            color: $darkPurple;
            font-size: 14px;
        }
    }
}

#client-add-main-div {
    padding: 0 !important;

    .client-add-step-div {
        width: 100%;
        height: calc(90vh - 25px);
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .add-client-left-div {
            width: 35%;
            height: 50%;
            align-self: center;
            padding: 25px 3vw;
            background-color: $lightGrey;

            .top {
                display: flex;
                flex-direction: column;

                .step-number-div {
                    width: 30px;
                    height: 30px;
                    background-color: $orange;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    align-items: center;

                    .step-number {
                        color: white;
                    }
                }

                .step-title {
                    font-family: "Open Sans", sans-serif;
                    color: $darkPurple;
                    font-weight: bolder;
                    font-size: 32px;
                }
            }
            .bottom {
                margin-top: 30px;

                .step-description {
                    font-family: "Open Sans", sans-serif;
                    color: $darkGrey;
                    font-size: 18px;
                }
            }
        }

        .add-client-right-div {
            width: 65%;
            background-color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 25px 3vw;

            .no-client-div {
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: center;
                align-items: center;

                .no-client-text {
                    font-family: "Open Sans";
                    color: $darkGrey;
                    font-size: 20px;
                }

                .no-client-arrow-button {
                    margin-left: 20px;
                    background-color: $orange;
                    padding: 5px;
                    border-radius: 5px;

                    .no-client-arrow-button-icon {
                        color: white;
                    }
                }
            }

            > div {
                width: 70%;
                height: 50%;
                align-self: center;
                display: flex;
                flex-direction: column;
                align-content: center;
                align-items: center;
                justify-content: center;

                .client-name-input {
                    margin-bottom: 50px;
                    width: 100%;
                    border-bottom: 0;
                }

                .button-title {
                    font-family: "Open Sans", sans-serif;
                    font-weight: bold;
                }

                .buttons-div {
                    margin-top: 20px;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    .submit-button-div,
                    .skip-button-div {
                        width: 49%;
                    }

                    .skip-button {
                        background-color: $darkPurple;
                    }
                }
            }

            .client-search-data-source-div {
                .tabs-div {
                    width: 100%;
                    margin-bottom: 10px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                }

                .client-input,
                .client-select,
                .client-checkbox {
                    border-bottom: 0;
                }

                .client-checkbox-form-control {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    margin-top: 10px;
                }

                .client-add-form-control {
                    width: 100%;
                    margin-top: 10px;
                }

                .disclaimer {
                    width: 100%;
                    text-align: start;
                    margin-top: 25px;
                    color: $darkGrey;
                    font-size: 0.8rem;
                }
            }

            .client-add-data-source-div {
                .card {
                    width: 100%;
                    background-color: white;
                    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

                    &:hover {
                        cursor: inherit;
                        background-color: white;
                    }

                    &:nth-child(3n + 2) {
                        margin-left: 0;
                        margin-right: 0;
                    }

                    .card-header {
                        border-bottom: 0;
                        padding-bottom: 15px;

                        .details-text {
                            text-transform: none;
                            color: $orange;
                        }

                        .details-icon {
                            color: $orange;
                            margin-left: 5px;
                        }
                    }

                    .card-content {
                        margin-top: 0;
                        padding-bottom: 25px;

                        .card-content-child {
                            .subtitle {
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }

                    .card-footer-with-select {
                        width: 100%;
                        padding: 10px 25px;
                        border-top: 1px dashed $darkGrey;
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }
        }
    }
}

#litigation-full-details-main-div {
    width: 50vw;
    align-self: center;

    .card {
        width: 100%;
        background-color: white;

        &:hover {
            cursor: inherit;
            background-color: white;
        }

        &:nth-child(3n + 2) {
            margin-left: 0;
            margin-right: 0;
        }

        .card-header {
            border-bottom: 0;
            padding-bottom: 15px;

            .details-text {
                text-transform: none;
                color: $orange;
            }

            .details-icon {
                color: $orange;
                margin-left: 5px;
            }
        }

        .card-content {
            margin-top: 0;
            padding-bottom: 25px;

            .card-content-child {
                .subtitle {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .card-content-child-details-parties {
                margin-top: 10px;
                padding-top: 10px;
                border-top: 1px dashed $darkGrey;

                .title {
                    font-family: "Open Sans";
                    color: $darkGrey;
                    font-size: 16px;
                    margin-right: 5px;
                }

                .subtitle {
                    color: $darkPurple;
                    font-family: "Open Sans";
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }
    }
}
