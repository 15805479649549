@import "./colors";

.navbar#navbar-subscription-change {
    border-bottom: 0px;
    background-color: $lightGrey;
}

div#subscription-change-main-div {
    flex-direction: column;

    .intervals-container {
        padding-top: 20px;
        width: 50%;

        .plan-name {
            font-size: 36px;
            font-weight: bolder;
            color: $darkPurple;
        }

        .plan-price {
            color: $orange;
            font-size: 25px;
        }

        .intervals-text {
            margin: 30px 0 15px 0;
            color: $darkGrey;
            font-size: 20px;
        }
    }

    .intervals-div {
        .interval {
            background-color: white;
            padding: 20px;
            display: flex;
            flex-direction: row;
            margin: 10px 0;
            align-items: center;

            &:hover {
                cursor: pointer;
            }

            .icon-div {
                width: 10%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .icon {
                    color: $darkPurple;
                }
            }

            .texts-div {
                width: 90%;
                display: flex;
                justify-content: space-between;

                .interval-in-months {
                    color: $darkPurple;
                    font-weight: bold;
                    font-size: 22px;
                }

                .discount {
                    color: $orange;
                    font-size: 18px;
                    font-weight: bold;
                }
            }
        }

        .selected-interval {
            border: 1px solid $orange;

            .texts-div {
                .interval-in-months {
                    font-size: 24px;
                }
            }
        }
    }
}
