@import "./colors";

#download-app {
    height: 100vh;
    background-color: $darkPurple;
    background-image: url("../images/download-app-bg.svg");
    background-repeat: no-repeat;
    background-position: center;
    display: flex;

    .container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        align-self: center;
        width: 100%;
        height: 80%;

        .md-logo,
        .buttons,
        .text {
            width: 70%;
            max-width: 500px;
        }

        .text {
            font-size: 35px;
            font-weight: bold;
            text-align: center;
            color: white;

            span {
                color: $orange;
            }
        }

        .buttons {
            display: flex;
            justify-content: space-between;

            a {
                display: flex;
                flex-direction: column;
                justify-content: center;
                max-width: 180px;
                width: 45%;

                img {
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }
}

@media only screen and (max-width: 550px) {
    #download-app {
        .container {
            height: 70%;

            .md-logo,
            .buttons,
            .text {
                width: 80%;
            }

            .text {
                font-size: 30px;

                span {
                    color: $orange;
                }
            }
        }
    }
}

@media only screen and (max-width: 470px) {
    #download-app {
        .container {
            height: 60%;
            padding: 0 25px;

            .md-logo,
            .buttons,
            .text {
                width: 100%;
            }

            .text {
                font-size: 26px;

                span {
                    color: $orange;
                }
            }
        }
    }
}
