@import "./colors";

.forgot-password-modal {
    width: 30vw;
    height: 60vh;
    min-width: 500px;
    min-height: 500px;
    display: flex;
    margin: 20vh auto;
    justify-content: center;
    background-color: white;

    .main-div {
        width: 95%;
        height: 95%;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        margin-top: 2.5%;
        justify-content: flex-start;

        .header {
            display: flex;
            justify-content: flex-end;

            .close-modal-button {
                padding: 5px;
                color: white;
                border-radius: 0;
                background-color: $orange;
            }
        }

        .content {
            width: 60%;
            height: 60%;
            margin-top: 15%;
            display: flex;
            align-self: center;
            flex-direction: column;
            justify-content: space-between;

            .content-text-div {
                height: 30%;

                .modal-title {
                    font-size: 30px;
                    font-weight: bold;
                    color: $darkPurple;
                }

                .modal-text {
                    font-size: 18px;
                    margin-top: 10px;
                    color: $lightPurple;
                }
            }

            .styled-form {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                height: 70%;

                .input {
                    margin-bottom: 10%;
                }
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .forgot-password-modal {
        width: 45vw;
    }
}

@media only screen and (max-width: 992px) {
    .forgot-password-modal {
        width: 55vw;
    }
}
