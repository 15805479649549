@import "./colors";
@import "./constants";
@import "react-big-calendar/lib/sass/styles";
@import "./Calendar_custom-event.scss";
@import "./Calendar_custom-toolbar.scss";
@import "./Calendar_custom-month.scss";

div#calendar-main-div {
    padding: 0;
    top: 0;
    min-height: 100vh;

    .calendar-div {
        height: 100vh;
        width: 80%;
        margin: 0 auto;

        .calendar-style {
            .rbc-month-view {
                position: absolute;
                top: 10vh;
                width: calc(100% - 6vw);
                height: 86vh;
                margin: 2vh auto;
                align-self: center;
                background-color: white;
            }

            .rbc-header {
                color: $darkPurple;
                text-transform: capitalize;
                font-size: 16px;
                padding: 5px 0;
            }

            .rbc-time-view {
                .rbc-label {
                    display: none;
                }

                .rbc-allday-cell {
                    height: 100%;
                    max-height: unset;
                }

                .rbc-time-content {
                    display: none;
                }
            }
        }
    }
}

#day-drawer-main-div {
    width: 50vw;
    height: 100vh;
    padding: 25px;
    display: flex;
    flex-direction: column;

    .day-drawer-header-div {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        .header-close-icon {
            align-content: flex-start;
            font-size: 24px;
            text-align: center;
            text-transform: capitalize;
            color: $error;
        }

        .day-text {
            font-family: "Open Sans", sans-serif;
            font-weight: bold;
            font-size: 24px;
            text-align: center;
            text-transform: capitalize;
            color: $darkPurple;
        }

        .placeholder-div {
            width: 10px;
        }
    }

    .hearing-cards-list {
        width: 100%;
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;

        .hearing-card-link {
            width: 49%;
            background-color: $lightGrey;
            border-radius: 5px;
            margin-top: 20px;
            text-decoration: none;

            .hearing-card-header {
                padding: 15px;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid $darkGrey;

                .hearing-card-title,
                .hearing-card-arrow {
                    font-size: 20px;
                    font-family: "Open Sans", sans-serif;
                    font-weight: bolder;
                    color: $darkPurple;
                }
            }

            .hearing-card-body {
                padding: 15px;

                .hearing-card-detail-item {
                    display: flex;

                    .hearing-detail-title,
                    .hearing-detail-value {
                        font-family: "Open Sans", sans-serif;
                        font-size: 16px;
                    }

                    .hearing-detail-title {
                        color: $darkGrey;
                        width: 40%;
                    }

                    .hearing-detail-value {
                        font-weight: bold;
                        color: $darkPurple;
                    }
                }
            }
        }
    }

    .no-hearings-for-date-div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .no-hearings-for-date-text {
            font-family: "Open Sans", sans-serif;
            color: $darkGrey;
            font-size: 20px;
        }
    }
}

@media only screen and (max-width: 1110px) {
    #day-drawer-main-div {
        width: 80vw;
    }
}
