.rbc-selected-cell {
    background-color: $orange;
}

.rbc-show-more {
    font-weight: bold;
    font-size: 85%;
    height: auto;
    line-height: normal;
    cursor: pointer;
    text-decoration: none;
    color: $darkGrey;
}

.rbc-date-cell {
    flex: 1 1 0;
    min-width: 0;
    padding-right: 5px;
    text-align: right;
    cursor: pointer;
}

.rbc-day-bg {
    cursor: pointer;

    &:hover {
        background-color: $lightGrey;
    }
}
