@import "./colors";

#groups-edit-main-div {
    .edit-group-form {
        display: flex;
        flex-direction: column;
        padding: 25px;
        background-color: white;
        border-radius: 5px;
        width: 45vw;
        min-width: 450px;

        .users-input-div {
            display: flex;
            margin: 20px 0;
            align-items: center;

            .users-input-text {
                padding-right: 10px;
                color: $darkPurple;
                font-weight: bolder;
                font-size: 14px;
            }

            .users-input {
                width: 45px;

                input {
                    text-align: center;
                }
            }
        }

        .save-button {
            margin-top: 40px;
        }
    }

    .center-loader {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
