@import "./colors";
@import "./navbar";
@import "./card";
@import "./constants";

.navbar#navbar-notifications-list {
    height: 15vh;
    min-height: 100px;
    width: calc(100vw - 250px);
    flex-direction: column;

    .navbar-top {
        width: calc(100vw - 250px);
        height: 10vh;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 3vw;
        border-bottom: 1px solid $lightGrey;
    }

    .navbar-bottom {
        width: calc(100vw - 250px);
        height: 5vh;
        min-height: 45px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 3vw;

        .tabs-div {
            width: 100%;
            height: 5vh;
            min-height: 45px;
            display: flex;
            justify-content: space-between;

            .tab {
                font-family: "Open Sans", sans-serif;
                height: 5vh;
                min-height: 45px;
            }

            .search-div {
                height: 5vh;
                min-height: 45px;
                width: calc(100% - 340px);
                display: flex;
                align-items: center;

                .search-icon {
                    margin-right: 10px;
                    color: $orange;
                }

                .search-input {
                    width: 100%;
                    font-size: 16px;
                    font-family: "Open Sans", sans-serif;
                    color: $darkPurple;
                }
            }
        }
    }
}

div#notifications-list-main-div {
    top: 15vh;
    min-height: 85vh;

    .card {
        .card-header {
            .notification-header {
                display: flex;

                .notification-header-text {
                    font-size: 16px;
                    color: $darkGrey;
                    align-self: center;
                }

                .notification-header-icon {
                    font-size: 16px;
                    color: $darkGrey;
                    align-self: center;
                    margin-left: 10px;
                    margin-right: 5px;
                }
            }
            .orange-circle {
                background-color: $orange;
                border-radius: 50%;
                height: 10px;
                width: 10px;
            }
        }

        .card-content {
            #notification-subject-div {
                background-color: inherit;
            }
        }
    }
}

#notification-details-drawer {
    width: 50vw;
    height: 100vh;
    display: flex;
    justify-content: flex-end;

    .notification-details-main-div {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .notification-details-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 10vh;
            border-bottom: 1px solid $lightGrey;
            padding: 0 5vw 0 2vw;

            .notification-details-header-data {
                display: flex;

                .notification-details-header-text {
                    font-size: 18px;
                    color: $darkGrey;
                    align-self: center;
                }

                .notification-details-header-icon {
                    font-size: 16px;
                    color: $darkGrey;
                    align-self: center;
                    margin-left: 10px;
                    margin-right: 5px;
                }
            }
            .notification-details-close-button {
                background-color: $orange;
                display: flex;
                justify-content: center;
                height: 30px;
                width: 30px;

                .notification-details-close-icon {
                    color: white;
                }
            }
        }

        .notification-details-content {
            padding: 40px 7vw 0 0;
            height: 80vh;
            overflow-y: auto;

            .notification-details-subject {
                font-size: 30px;
                font-weight: bold;
                color: $darkPurple;
                padding-bottom: 15px;
                border-bottom: 1px solid $lightGrey;
            }

            .notification-details-modifications-div {
                margin-top: 50px;

                .notification-details-modifications-text {
                    font-size: 16px;
                    font-weight: bold;
                    color: $darkPurple;
                    margin-bottom: 10px;
                }

                .notification-details-message {
                    font-size: 18px;
                    color: $darkGrey;

                    &.litigation-number-text {
                        font-weight: bold;
                        color: $darkPurple;
                        margin-top: 15px;
                    }

                    &.party-text {
                        margin-left: 10px;
                        font-weight: bold;
                    }

                    &.party-value {
                        margin-left: 20px;
                    }
                }
            }
        }

        .notification-details-buttons-div {
            padding: 0 5vw 0 0;
            height: 10vh;
            padding-top: 10px;
        }
    }
}

#empty-notifications-list-main-div {
    top: 10vh;
    min-height: 90vh;
    background-color: white;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    .no-notifications-title {
        margin-top: 10vh;
        text-align: center;
        color: $darkPurple;
        font-size: 24px;
        font-weight: bold;
        font-family: "Open Sans", sans-serif;
    }
}
