@import "./colors";

.navbar#navbar-subscription-plans {
    border-bottom: 0px;
    background-color: $lightGrey;
    z-index: -1;
}

div#subscription-plans-main-div {
    top: 0;
    min-height: 100%;
    position: absolute;
    flex-wrap: nowrap;
    flex-direction: column;
    padding: 20px 3vw 0 3vw;

    .page-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-content: center;

        .left {
            width: 40%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .page-title {
                font-size: 32px;
                font-weight: bolder;
                color: $darkPurple;
            }

            .page-description {
                color: $darkGrey;
                font-size: 18px;
            }
        }

        .right {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .back-button {
                border-radius: 5px;
                display: flex;
                padding: 10px 25px;
                align-content: center;
                align-items: center;
                background-color: white;

                .back-button-icon {
                    margin-right: 10px;
                }

                .back-button-title {
                    font-weight: bold;
                    color: $darkPurple;
                    font-size: 14px;
                }
            }
        }
    }

    .page-body {
        padding: 40px 0;

        .card {
            &:hover {
                background-color: white;
                cursor: inherit;
            }

            .card-header {
                padding: 15px;

                .card-title {
                    font-size: 26px;
                    text-transform: uppercase;
                }

                .plan-price-div {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                }

                .plan-price,
                .plan-frequency {
                    text-align: right;
                }

                .plan-price {
                    color: $orange;
                    font-weight: bolder;
                    font-size: 18px;
                }

                .plan-frequency {
                    font-size: 14px;
                    color: $darkGrey;
                    min-height: 20px;
                }
            }

            .card-content {
                padding-bottom: 0;

                .card-content-child {
                    padding: 5px;

                    &:nth-child(odd) {
                        background-color: white;
                    }

                    &:nth-child(even) {
                        background-color: $lightGrey;
                    }

                    .title {
                        width: 85%;
                    }

                    .subtitle {
                        width: 15%;
                        text-align: center;
                    }
                }

                .active-plan-div {
                    margin: 15px 0;
                    padding: 15px 0;
                    align-self: center;

                    .active-plan-text {
                        color: $orange;
                        font-size: 18px;
                        font-weight: bold;
                        text-align: center;
                    }
                }

                .activate-plan-button {
                    margin: 15px 0;
                }
            }
        }
    }

    .plans-loading-conatiner {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10rem;
    }
}
