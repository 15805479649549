@import "./colors";

#navbar-groups {
    .navbar-left {
        display: flex;
        align-items: center;
        width: 80%;

        .navbar-title {
            font-size: 20px;
        }

        .navbar-stepper {
            margin-left: 30px;
            width: 85%;
            padding: 0 15px;
            border-left: 1px solid $lightGrey;
        }
    }

    .cancel-button {
        border: 1px solid $darkPurple;
        border-radius: 5px;
        display: flex;
        padding: 10px 25px;
        align-content: center;
        align-items: center;

        .cancel-button-title {
            font-weight: bold;
            color: $darkPurple;
            font-size: 14px;
        }
    }
}

#groups-main-div {
    padding: 0 !important;
    height: calc(90vh - 25px);
    display: flex;
    flex-direction: row;

    .left {
        width: 35%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: $lightGrey;
        padding: 0 3vw;

        .title {
            font-family: "Open Sans", sans-serif;
            color: $darkPurple;
            font-weight: bolder;
            font-size: 32px;
        }

        .description {
            margin-top: 30px;
            font-size: 18px;
            color: $darkGrey;
        }
    }

    .right {
        width: 65%;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .error-div {
            margin-bottom: 25px;
        }

        .add-group-form {
            width: 60%;
            min-width: 400px;
            align-self: center;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .step-title {
                margin-bottom: 15px;
                font-size: 20px;
                color: $darkPurple;
                font-weight: bolder;

                &:not(:first-child) {
                    margin-top: 100px;
                    margin-bottom: 30px;
                }
            }

            .group-name-input {
                margin-bottom: 25px;
            }

            .add-group-button {
                margin-top: 100px;
            }
        }
    }
}

@media only screen and (max-width: 900px) {
    #groups-main-div {
        height: auto;
        justify-content: flex-start;
        padding: 25px 3vw !important;
        background-color: white;

        .left,
        .right {
            height: auto;
            width: 100%;
            background-color: white;
        }

        .right {
            padding: 25px 3vw;
        }
    }
}
