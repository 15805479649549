@import "colors";

#limit-passed-container {
    height: 100vh;
    width: calc(100% - 250px);
    position: fixed;
    left: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0.5);
    backdrop-filter: blur(0.2rem);
    z-index: 100;

    .limit-passed {
        width: 80%;
        height: 80%;
        max-width: 500px;
        max-height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 4.5rem 5rem 3.5rem 5rem;
        background-color: white;
        border-radius: 5px;

        .top {
            .img-container {
                width: 100%;
                height: 5rem;
                display: flex;
                justify-content: center;

                .limit-img {
                    height: 100%;
                }
            }

            .title,
            p {
                text-align: center;
                padding: 0 1rem;
            }

            .title {
                margin-top: 2.3rem;
                font-size: 1.625rem;
                color: $darkPurple;
                font-weight: bold;
            }

            p {
                margin-top: 0.6rem;
                font-size: 0.875rem;
                color: $darkGrey;
            }
        }

        .buttons {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-top: 2.75rem;

            .button {
                margin-top: 0.5rem;
                text-decoration: none;

                &.upgrade-subscription {
                    button {
                        background-color: $success;
                    }
                }

                &:nth-child(1) {
                    margin-top: 0;
                }

                .button-text {
                    font-weight: bold;
                    text-transform: none;
                }

                .outlined,
                .clear {
                    border-color: $orange;

                    .button-text {
                        color: $orange;
                        border-color: $orange;
                    }
                }

                .clear {
                    .button-text {
                        font-weight: 600;
                    }
                }
            }
        }
    }
}
