.rbc-event {
    background-color: $orange;
    border-radius: 5;
    cursor: pointer;

    .rbc-slot-selecting & {
        cursor: inherit;
    }

    &.rbc-selected {
        background-color: $orange;
    }

    &:focus {
        outline: none;
    }
}
