@import "./colors";

div#subscription-change-main-div {
    display: flex;
    flex-direction: column;
    align-content: center;

    .container {
        width: 700px;
        height: 50vh;
        min-height: 500px;
        margin-top: 40px;
        align-self: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .page-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .back-button {
                background-color: white;
                border-radius: 5px;
                display: flex;
                padding: 10px;
                align-content: center;
                align-items: center;

                .back-button-icon {
                    margin-right: 5px;
                    height: 20px;
                }

                .back-button-title {
                    font-weight: bold;
                    color: $darkPurple;
                    font-size: 14px;
                }
            }

            .page-title {
                font-size: 40px;
                font-weight: bolder;
                margin-right: 10px;
                color: $darkPurple;
            }
        }

        .current-subscription-info {
            background-color: white;
            padding: 40px;
            display: flex;
            justify-content: center;

            .text-div {
                width: 60%;
                align-self: center;

                .current-subscription-text {
                    font-size: 24px;
                    font-weight: bold;
                    color: $darkPurple;
                    text-align: center;
                }

                .date-text {
                    color: $orange;
                    font-size: 40px;
                    font-weight: bold;
                    text-align: center;
                }

                .disclaimer {
                    text-align: center;
                    font-size: 18px;
                    color: $darkGrey;
                }
            }
        }

        .button-div {
            margin-top: 30px;
        }
    }
}
