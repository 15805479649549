@import "./colors";

div#promo-code-main-div {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .container {
        width: 600px;
        height: 700px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: white;
        border-radius: 5px;
        padding: 0 20px;

        .header,
        .footer {
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .close-button {
                background-color: $orange;
                border-radius: 2px;
                padding: 3px;

                .close-button-icon {
                    color: white;
                }
            }
        }

        .content {
            width: 480px;
            display: flex;
            align-self: center;
            flex-direction: column;

            .texts {
                display: flex;
                align-self: center;
                flex-direction: column;

                .title {
                    font-size: 30px;
                    font-weight: bold;
                    color: $darkPurple;
                    margin-bottom: 15px;
                }

                .subtitle {
                    font-size: 18px;
                    color: $darkGrey;
                    margin-top: 5px;
                }
            }

            .code-div {
                display: flex;
                flex-direction: column;
                margin-top: 50px;

                .error-div {
                    width: 100%;
                    margin-top: -25px;
                }

                .use-code-text {
                    font-size: 16px;
                    color: $darkPurple;
                    margin: 15px 0;
                }

                .promo-code-input {
                    width: 100%;
                    font-size: 18px;
                    color: $darkPurple;
                    margin-bottom: 20px;
                }
            }
        }
    }
}
