@import "./colors";

div#support-main-div {
    padding: 0;
    top: 0;
    min-height: 100vh;
    flex-direction: row;
    justify-content: space-between;

    .support-left-div,
    .support-right-div {
        width: 50%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .support-left-div {
        padding-left: 50px;

        .support-big-icon-div {
            display: flex;
            align-self: flex-start;
            padding: 0;

            .support-big-icon {
                font-size: 200px;
                color: $darkPurple;
            }
        }

        .main-text,
        .secondary-text {
            font-family: "Open Sans", sans-serif;
        }

        .main-text {
            color: $darkPurple;
            font-size: 56px;
            font-weight: bolder;

            .main-text-span {
                color: $success;
            }
        }

        .secondary-text {
            font-size: 20px;
            color: $darkGrey;
        }
    }

    .support-right-div {
        padding-right: 50px;

        .support-card {
            background-color: white;
            padding: 20px;
            border-radius: 2px;

            &:nth-child(2) {
                margin-top: 30px;
            }

            .support-card-description-text {
                font-family: "Open Sans", sans-serif;
                font-size: 20px;
                color: $darkPurple;
                margin-bottom: 25px;
            }

            .button-large {
                padding: 0;

                .button-text {
                    font-family: "Open Sans", sans-serif;
                    font-size: 18px;
                    font-weight: bold;
                    color: white;
                    width: 90%;
                    text-align: start;
                    padding: 15px;
                }

                .button-icon-div {
                    width: 10%;
                    min-width: 60px;
                    border-left: 1px solid darken($color: $success, $amount: 2);
                    padding: 15px;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    align-items: center;

                    .button-icon {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .styled-form {
                margin-top: 45px;

                .input {
                    margin-bottom: 25px;
                }

                .button-icon-div.orange {
                    border-left: 1px solid darken($color: $lightGrey, $amount: 2);
                }
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    div#support-main-div {
        .support-left-div {
            .support-big-icon-div {
                .support-big-icon {
                    font-size: 150px;
                }
            }

            .main-text {
                font-size: 48px;

                br {
                    display: none;
                }
            }

            .secondary-text {
                font-size: 18px;
            }
        }

        .support-right-div {
            padding-right: 25px;
        }
    }
}

@media only screen and (max-width: 1000px) {
    div#support-main-div {
        flex-direction: column;
        justify-content: center;

        .support-left-div,
        .support-right-div {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
        }

        .support-left-div {
            .support-big-icon-div {
                .support-big-icon {
                    font-size: 150px;
                }
            }

            .main-text {
                font-size: 46px;
            }
        }

        .support-right-div {
            padding: 50px;
        }
    }
}
