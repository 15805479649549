@import "./colors";

.navbar#navbar-sync-google-calendar {
    width: 100vw;
    left: 0;
    min-height: 0;
}

div#sync-google-calendar-main-div {
    top: 10vh;
    left: 0;
    width: 100vw;
    min-height: 90vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-content: center;
    background-color: $lightGrey;

    .sync-text-div {
        margin: 30px 0;
        width: 100%;

        .title-div {
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;

            .title {
                font-size: 32px;
                font-weight: bolder;
                font-family: "Open Sans";
                color: $darkPurple;
                text-align: center;
                display: flex;
                align-items: flex-end;
                height: 60px;

                .google-logo-span {
                    margin-left: 10px;
                    height: 80%;

                    img {
                        height: 75%;
                    }
                }
            }
        }

        .secondary-text {
            color: $darkGrey;
            font-family: "Open Sans";
            text-align: center;
            width: 50%;
            margin: 10px auto;
        }
    }

    .sync-card {
        background-color: white;
        margin: 20px auto;
        width: 40vw;

        .card-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0 35px;
            width: 100%;
            border-bottom: 1px solid $lightGrey;
            background-color: #fcfcfc;

            .img-div {
                width: 15%;
                border-right: 1px solid $lightGrey;
                padding: 20px 0;

                img {
                    max-height: 70px;
                }
            }

            .right {
                width: 85%;
                padding: 20px;

                .text {
                    color: $darkPurple;
                    font-size: 22px;
                }
            }
        }

        .new-calendar-div,
        .existing-calendar-div {
            display: flex;
            flex-direction: column;
            padding: 60px 35px;
            padding-top: 10px;

            .circular-progress {
                margin-top: 25px;
                align-self: center;
            }

            .button {
                margin-top: 25px;
            }

            .link {
                margin-top: 50px;
                display: flex;
                flex-direction: row;
                justify-content: center;

                .button-text {
                    color: $orange;
                }
            }
        }
    }

    div.sync-methods-main-div {
        width: 60vw;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        .cards-container {
            justify-content: center;

            .card {
                width: calc(48% - 10px);
                max-width: 550px;
                max-height: 600px;

                &:hover {
                    background-color: white;
                    cursor: inherit;
                }

                .card-content {
                    padding-top: 40px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                img {
                    max-height: 100px;
                    margin-bottom: 30px;
                }

                .title {
                    color: $darkPurple;
                    margin: 0 auto;
                    text-align: center;
                    font-size: 24px;
                }

                .info-text {
                    width: 65%;
                    min-width: 300px;
                    margin-top: 20px;
                    font-size: 18px;
                    color: $darkGrey;
                    text-align: center;
                }

                .card-button-div {
                    border-top: 1px solid $lightGrey;
                    margin-top: 50px;
                    width: 100%;
                    display: flex;
                    justify-content: center;

                    .link {
                        width: 100%;
                        height: 100%;
                        padding: 20px;
                        display: flex;
                        justify-content: center;

                        &:hover {
                            background-color: #f7f7fc;
                        }

                        .icon {
                            color: $orange;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1300px) {
    div#sync-google-calendar-main-div {
        .sync-card {
            width: 60vw;
        }

        div.sync-methods-main-div {
            width: 90vw;
        }
    }
}

@media only screen and (max-width: 1000px) {
    div#sync-google-calendar-main-div {
        .sync-card {
            width: 80vw;
        }

        div.sync-methods-main-div {
            width: 90vw;
            flex-direction: column;

            .cards-container {
                justify-content: center;

                .card {
                    width: 100%;
                }
            }
        }
    }
}
