@import "./colors";

.select-phase-modal {
    width: 30vw;
    min-width: 500px;
    height: 50vh;
    margin: 20vh auto;
    display: flex;
    justify-content: center;
    background-color: white;

    .main-div {
        display: flex;
        flex-direction: column;
        padding: 15px;

        .header {
            display: flex;
            justify-content: flex-end;

            .close-modal-button {
                padding: 5px;
                border-radius: 5px;
                background-color: $orange;

                .close-button-icon {
                    color: white;
                }
            }
        }

        .content {
            width: 80%;
            margin-top: 15px;
            display: flex;
            align-self: center;
            flex-direction: column;

            .content-text-div {
                text-align: center;

                .modal-title {
                    font-size: 30px;
                    font-weight: bold;
                    color: $darkPurple;
                }

                .modal-text {
                    font-size: 18px;
                    margin-top: 10px;
                    color: $lightPurple;
                }
            }

            .styled-form-control {
                width: 100%;
                margin-top: 20px;
            }

            .buttons-div {
                margin-top: 40px;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .refresh-component {
        width: 45vw;
    }
}

@media only screen and (max-width: 992px) {
    .refresh-component {
        width: 55vw;
    }
}
