@import "./colors";

div#ticket-success-main-div {
    position: absolute;
    top: 0;
    height: 100vh;
    padding: 0;

    .ticket-success-sm-div {
        background-color: white;
        width: 40vw;
        height: 80vh;
        margin: 10vh auto;
        padding: 50px;
        display: flex;
        flex-direction: column;

        .check-image-div {
            width: 25%;
            margin-bottom: 20px;
        }

        .ticket-success-main-text {
            font-family: "Open Sans", sans-serif;
            font-size: 42px;
            font-weight: bolder;
            line-height: 1;
            color: $darkPurple;

            span {
                color: $success;
            }
        }

        .contact-details-div {
            margin-top: 50px;

            .details-main-text {
                font-family: "Open Sans", sans-serif;
                color: $darkGrey;
                font-size: 22px;
            }

            .contact-details-list {
                margin-top: 15px;

                .detail-text {
                    font-family: "Open Sans", sans-serif;
                    font-size: 22px;
                    font-weight: bolder;
                    color: $darkPurple;
                    padding: 15px;
                    background-color: $lightGrey;

                    &:nth-child(2) {
                        background-color: white;
                    }
                }
            }
        }

        .to-projects-link {
            margin: 50px 0;
            text-decoration: "none";
            display: "flex";
            justify-content: "center";

            .to-projects-link-text {
                font-family: "Open Sans", sans-serif;
                font-size: 22px;
                font-weight: bold;
            }
        }
    }
}
