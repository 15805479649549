@import "./colors";
@import "./SubscriptionChange_navbar";

div#subscription-confirm-main-div {
    justify-content: center;
    align-content: center;

    .container {
        width: 100%;
        max-height: 60vh;
        min-height: 500px;
        align-self: center;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .selected-plan-info {
            max-width: 70%;
            padding: 30px;
            margin-right: 10px;
            background-color: white;

            .info-header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-content: center;
                margin-bottom: 20px;

                .confirm-title {
                    font-size: 40px;
                    color: $darkPurple;
                    font-weight: bold;
                }

                .change-billing-data-link {
                    padding: 3px 10px;
                    border: 1px solid $orange;
                    border-radius: 5px;
                    text-decoration: none;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    align-self: center;
                    margin-left: 15px;

                    .change-billing-data-text {
                        color: $orange;
                        font-size: 16px;
                    }

                    .change-billing-data-icon {
                        margin-right: 7px;
                        color: $orange;
                    }
                }
            }

            .content-div {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                .section-title {
                    padding: 0 15px;
                    color: $darkGrey;
                    font-size: 15px;
                    font-weight: bold;
                    text-transform: uppercase;
                    margin-bottom: 15px;
                }

                .info-list {
                    .info-div {
                        padding: 5px 15px;
                        display: flex;

                        &:nth-child(odd) {
                            background-color: $lightGrey;
                        }

                        .title {
                            width: 100px;
                            color: $darkPurple;
                            font-size: 15px;
                            font-weight: bold;
                        }

                        .value {
                            width: calc(100% - 100px);
                            color: $darkPurple;
                            font-size: 16px;
                        }
                    }
                }

                .left,
                .right {
                    width: 50%;
                    min-width: 250px;
                    padding: 20px;
                }

                .left {
                    border-right: 1px solid $darkGrey;
                }
            }
        }

        .price-div {
            background-color: white;
            padding: 50px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 30%;

            .about-plan-div {
                .selected-plan-text {
                    font-size: 30px;
                    color: $darkPurple;
                    font-weight: bold;
                    text-align: center;
                    border-bottom: 1px solid $lightGrey;
                    padding-bottom: 30px;
                }

                .price-text {
                    margin-top: 20px;
                    font-size: 16px;
                    color: $darkGrey;
                    text-align: center;
                }

                .price {
                    font-size: 30px;
                    color: $orange;
                    font-weight: bold;
                    text-align: center;
                }
            }

            .button-div {
                .discount-code-modal-button {
                    margin-bottom: 5px;

                    .discount-text {
                        font-size: 12px;
                        color: $orange;
                    }
                }

                .discount-div {
                    padding: 5px 0;
                    margin-bottom: 15px;
                    background-color: $lightGrey;

                    .discount-text {
                        font-size: 16px;
                        font-weight: bold;
                        text-align: center;
                        color: $orange;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1500px) {
    div#subscription-confirm-main-div {
        .container {
            max-height: 80vh;
            min-height: 650px;

            .selected-plan-info {
                .content-div {
                    .left,
                    .right {
                        width: 100%;
                    }

                    .left {
                        border: none;
                    }
                }
            }
        }
    }
}
