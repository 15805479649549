@import "./constants";
@import "./colors";

.navbar#navbar-subscriptions-add {
    .navbar-left {
        display: flex;
        align-items: center;
        width: 80%;

        .navbar-title {
            font-size: 20px;
            width: 15%;
        }

        .navbar-stepper {
            margin-left: 30px;
            width: 85%;
            padding: 0 15px;
            border-left: 1px solid $lightGrey;
        }
    }

    .cancel-button {
        border: 1px solid $darkPurple;
        border-radius: 5px;
        display: flex;
        padding: 10px 25px;
        align-content: center;
        align-items: center;

        .cancel-button-title {
            font-weight: bold;
            color: $darkPurple;
            font-size: 14px;
        }
    }
}

#subscriptions-add-main-div {
    padding: 0 !important;

    .subscriptions-add-step-div {
        width: 100%;
        height: calc(90vh - 25px);
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .subscriptions-add-left-div {
            width: 35%;
            align-self: center;
            padding: 25px 3vw;
            background-color: $lightGrey;

            .top {
                display: flex;
                flex-direction: column;

                .step-number-div {
                    width: 30px;
                    height: 30px;
                    background-color: $orange;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    align-items: center;

                    .step-number {
                        color: white;
                    }
                }

                .step-title {
                    font-family: "Open Sans", sans-serif;
                    color: $darkPurple;
                    font-weight: bolder;
                    font-size: 32px;
                }
            }
            .bottom {
                margin-top: 30px;

                .step-description {
                    font-family: "Open Sans", sans-serif;
                    color: $darkGrey;
                    font-size: 18px;
                }
            }
        }

        .subscriptions-add-right-div {
            width: 65%;
            background-color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 25px 3vw;

            .no-subscription {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;

                .empty-text {
                    font-family: "Open Sans";
                    color: $darkGrey;
                    font-size: 20px;
                }

                .go-to-form-button {
                    margin-left: 20px;
                    background-color: $orange;
                    padding: 5px;
                    border-radius: 5px;

                    .go-to-form-button-icon {
                        color: white;
                    }
                }
            }

            .back-button {
                margin-top: 10px;
                align-self: flex-end;

                .back-button-text {
                    font-family: "Open Sans", sans-serif;
                    color: $darkGrey;
                    font-size: 12px;
                }

                .back-button-icon {
                    color: $darkGrey;
                    margin-left: 5px;
                }
            }

            > div {
                width: 70%;
                height: 50%;
                align-self: center;
                display: flex;
                flex-direction: column;
                align-content: center;
                align-items: center;
                justify-content: center;

                .project-name-input {
                    margin-bottom: 50px;
                    width: 100%;
                    border-bottom: 0;
                }

                .button-title {
                    font-family: "Open Sans", sans-serif;
                    font-weight: bold;
                }
            }

            .subscription-search-data-source-div {
                .tabs-div {
                    width: 100%;
                    margin-bottom: 30px;

                    .tabs {
                        .tab {
                            flex: 1 1 100%;

                            &:first-child {
                                border-left: 0;
                            }

                            &:last-child {
                                border-right: 0;
                            }
                        }
                    }
                }

                .submit-button {
                    width: 100%;
                    margin-top: 20px;
                }

                .subscription-select,
                .subscription-input {
                    border-bottom: 0;
                }

                .subscriptions-add-form-control {
                    width: 100%;
                    margin-top: 10px;
                }

                .subscriptions-add-form-control.date-select {
                    border-bottom: 1px solid $darkGrey;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-end;

                    .date-picker {
                        width: 100%;
                    }
                }
            }

            .subscriptions-add-data-source-div {
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 10px 0;

                .litigations-list {
                    width: 100%;
                    overflow: auto;
                    padding: 5px;

                    .cards-container {
                        .card {
                            width: 100%;
                            background-color: white;
                            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

                            &:hover {
                                cursor: inherit;
                                background-color: white;
                            }

                            .card-header {
                                border-bottom: 0;
                                padding-bottom: 15px;

                                .details-text {
                                    text-transform: none;
                                    color: $orange;
                                }

                                .details-icon {
                                    color: $orange;
                                    margin-left: 5px;
                                }
                            }

                            .card-content {
                                margin-top: 0;
                                padding-bottom: 25px;

                                .card-content-child {
                                    .subtitle {
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }
                                }
                            }

                            .card-footer-with-select {
                                width: 100%;
                                padding: 10px 25px;
                                border-top: 1px dashed $darkGrey;
                                display: flex;
                                justify-content: space-between;
                            }
                        }
                    }
                }

                .litigations-empty-div {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .empty-text {
                        font-family: "Open Sans";
                        color: $darkGrey;
                        font-size: 20px;
                    }

                    .go-to-form-button {
                        margin-left: 20px;
                        background-color: $orange;
                        padding: 5px;
                        border-radius: 5px;

                        .go-to-form-button-icon {
                            color: white;
                        }
                    }
                }

                .litigations-no-results-div {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .no-results-left {
                        width: 85%;
                        text-align: center;

                        .no-results-text {
                            font-family: "Open Sans";
                            color: $darkGrey;
                        }
                    }

                    .no-results-right {
                        width: 15%;

                        .go-to-form-button {
                            margin-left: 20px;
                            background-color: $orange;
                            padding: 15px;
                            border-radius: 5px;

                            .go-to-form-button-icon {
                                color: white;
                            }
                        }
                    }
                }

                .litigations-loading-component {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .loading-component-text {
                        font-family: "Open Sans";
                        color: $darkGrey;
                        font-size: 20px;
                    }

                    .circular-progress {
                        margin: 10px 0;
                    }
                }

                .button-save-subscription {
                    margin-top: 10px;
                }
            }

            .subscription-choose-project {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 10px 0;

                .choose-existing-project,
                .create-new-project {
                    width: 100%;

                    .title {
                        font-size: 1.8rem;
                        text-align: center;
                        font-weight: bold;
                        color: $darkPurple;
                        margin-bottom: 1rem;
                    }
                }

                .choose-existing-project {
                    padding: 2rem 1rem 7rem 1rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    .styled-form-control {
                        width: 100%;
                        margin: 20px 0;
                    }

                    .circular-progress {
                        margin: 1.5rem 0;
                    }

                    .no-data-text {
                        width: 100%;
                        margin-top: 5px;
                        text-align: center;
                        font-family: "Open Sans", sans-serif;
                        color: $darkGrey;
                        font-size: 16px;
                    }
                }

                .create-new-project {
                    position: relative;
                    background-color: $lightGrey;
                    padding: 2rem 1rem;

                    .divider {
                        width: 10rem;
                        height: 4rem;
                        background-color: $lightGrey;
                        border-top-left-radius: 50%;
                        border-top-right-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        top: -4rem;
                        right: calc(100% - (50% + 5rem));

                        .divider-text {
                            font-size: 1.6rem;
                            text-align: center;
                            text-transform: uppercase;
                            font-weight: bold;
                            color: $darkPurple;
                        }
                    }
                }

                .litigations-selected-empty-div {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .empty-text {
                        font-family: "Open Sans";
                        color: $darkGrey;
                        font-size: 20px;
                        width: 50ch;
                        text-align: center;
                    }

                    .go-to-form-button {
                        margin-left: 20px;
                        background-color: $orange;
                        padding: 5px;
                        border-radius: 5px;

                        .go-to-form-button-icon {
                            color: white;
                        }
                    }
                }
            }
        }
    }
}

#litigation-full-details-main-div {
    width: 50vw;
    align-self: center;

    .card {
        width: 100%;
        background-color: white;

        &:hover {
            cursor: inherit;
            background-color: white;
        }

        &:nth-child(3n + 2) {
            margin-left: 0;
            margin-right: 0;
        }

        .card-header {
            border-bottom: 0;
            padding-bottom: 15px;

            .details-text {
                text-transform: none;
                color: $orange;
            }

            .details-icon {
                color: $orange;
                margin-left: 5px;
            }
        }

        .card-content {
            margin-top: 0;
            padding-bottom: 25px;

            .card-content-child {
                .subtitle {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .card-content-child-details-parties {
                margin-top: 10px;
                padding-top: 10px;
                border-top: 1px dashed $darkGrey;

                .title {
                    font-family: "Open Sans";
                    color: $darkGrey;
                    font-size: 16px;
                    margin-right: 5px;
                }

                .subtitle {
                    color: $darkPurple;
                    font-family: "Open Sans";
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }
    }
}
