@import "colors";

#insufficient-permissions-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    background-color: $secondaryDarkPurple;

    .top-container {
        height: 100%;
        width: 100%;
        padding: 4.35rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        .users-icon {
            width: 120px;
        }

        .primary-text {
            margin: 1.7rem 0 0.75rem 0;
            text-align: center;
            font-size: 1.875rem;
            font-weight: bold;
            color: white;

            span {
                color: $orange;
            }
        }

        .secondary-text {
            text-align: center;
            color: white;
            font-weight: lighter;
        }

        .profiles-container {
            width: 100%;
            height: 100%;
            display: flex;
            align-self: center;
            align-items: center;
            justify-content: center;

            .scrollable-profiles {
                overflow: auto;
                white-space: nowrap;
                max-width: 62rem;

                .profile-info-container {
                    display: inline-block;
                    margin: 0 0.625rem;
                    height: 8.125rem;
                    width: 14.5rem;

                    .profile-info {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        background-color: $darkPurple;

                        .user-icon-container {
                            width: 2.5rem;
                            height: 2.5rem;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 100%;
                            background-color: $secondaryDarkPurple;
                        }

                        .user-name {
                            margin-top: 0.5rem;
                            font-size: 0.875rem;
                            font-weight: bold;
                            color: white;
                        }

                        .user-email {
                            font-size: 0.75rem;
                            font-weight: lighter;
                            color: $lightPurple;
                        }
                    }
                }
            }

            &.single-profile {
                .scrollable-profiles {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .profile-info-container {
                        width: 100%;
                        max-width: 29rem;
                    }
                }
            }

            .arrow {
                color: $orange;

                &.left {
                    margin-right: 2.5rem;
                }

                &.right {
                    margin-left: 2.5rem;
                }
            }
        }
    }

    .bottom-container {
        width: 100%;
        height: 8.125rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        border-top: 1px solid $lightPurple;

        .back-button {
            width: 90%;
            height: 2.875rem;
            max-width: 28.75rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $orange;
            text-decoration: none;
            border-radius: 5px;

            p {
                font-size: 0.8125rem;
                font-weight: bold;
                color: white;
            }
        }
    }

    .center-loader {
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
