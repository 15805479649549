@import "./colors";

#entity-select-main-div {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $secondaryDarkPurple;

    .container {
        background-color: $darkPurple;
        width: 30vw;
        height: 80vh;
        min-width: 500px;
        padding: 40px;

        .top {
            height: 150px;

            .header-text {
                font-size: 20px;
                font-weight: bolder;
                color: white;
            }
        }

        .bottom {
            height: calc(100% - 120px);
            margin-top: 5px;

            .loading {
                display: flex;
                justify-content: center;
                margin-top: 100px;
            }

            .entities-list {
                height: 100%;
                overflow-y: auto;
                overflow-x: hidden;

                .entity-details {
                    display: block;
                    padding: 10px;
                    margin-top: 20px;
                    border-radius: 5px;
                    text-decoration: none;
                    background-color: white;

                    &:first-child {
                        margin-top: 0;
                    }

                    &:hover {
                        cursor: pointer;
                        background-color: $lightGrey;
                    }

                    &.disabled {
                        pointer-events: none;
                        background-color: $whiteDisabled;

                        .entity-name {
                            color: $disabledDarkPurple;
                        }

                        .inactive-text {
                            color: $lightGrey;
                            font-size: 0.9rem;
                        }
                    }

                    .entity-name {
                        width: 75%;
                        font-weight: bold;
                        font-size: 20px;
                        color: $darkPurple;
                    }
                }
            }
        }
    }

    .left,
    .right {
        width: 50vw;
        height: 100vh;
        min-width: 350px;
    }

    .left {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .logo-div {
            width: 20vw;
            align-self: center;
            margin-bottom: 10vh;

            img {
                max-width: 100%;
            }
        }

        .styled-form {
            width: 20vw;
            display: flex;
            align-self: center;
            padding-bottom: 30vh;
            flex-direction: column;

            .input {
                padding: 10px 0;
                margin: 15px 0;
                color: white;
            }
        }

        .back-button {
            display: flex;
            color: $orange;
            margin: 10px 0 20px 0;
            flex-direction: row;
            align-self: flex-start;
            justify-content: flex-start;

            .back-button-text {
                font-weight: 500;
                align-items: flex-start;
                text-transform: initial;
                font-family: "Open Sans", sans-serif;
                text-decoration: underline;
            }
        }
    }

    .right {
        display: flex;
        background-color: $darkPurple;
    }
}

@media only screen and (max-width: 1450px) {
    #entity-select-main-div {
        .left {
            .logo-div {
                width: 25vw;
            }

            .styled-form {
                width: 25vw;
            }
        }
    }
}
@media only screen and (max-width: 1200px) {
    #entity-select-main-div {
        .left {
            .logo-div {
                width: 30vw;
            }

            .styled-form {
                width: 30vw;
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    #entity-select-main-div {
        .left {
            .left-main-div {
                width: 35vw;

                .page-title {
                    font-size: 30px;
                }

                .page-description {
                    font-size: 16px;
                    margin-top: 10px;
                }

                .styled-form {
                    padding: 30px 0;

                    .password-input {
                        margin: 10px 0;
                    }

                    .password-changed-text {
                        margin-top: 25px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    //
}
