@import "./colors";

.navbar#navbar-change-subscription {
    .navbar-left {
        display: flex;
        align-items: center;
        width: 80%;

        .navbar-title {
            font-size: 20px;
            width: 25%;
            min-width: 255px;
        }

        .navbar-stepper {
            margin-left: 30px;
            width: 75%;
            min-width: calc(100% - 255px);
            padding: 0 15px;
            border-left: 1px solid $lightGrey;
        }
    }

    .back-button {
        border: 1px solid $darkPurple;
        border-radius: 5px;
        display: flex;
        padding: 7px 10px;
        align-content: center;
        align-items: center;

        .back-button-icon {
            margin-right: 5px;
            height: 20px;
        }

        .back-button-title {
            font-weight: bold;
            color: $darkPurple;
            font-size: 14px;
        }
    }
}
