@import "./colors";

.entity-switch {
    width: 100%;

    .entity-select {
        width: 100%;
        max-width: 150px;
        font-size: 13px;
        color: $orange;
        padding-bottom: 5px;
        z-index: 10009999;
    }

    .MuiInput-underline::before {
        border-bottom: 1px solid #444680 !important;
    }

    .MuiSelect-icon {
        color: #444680;
    }

    .entity-name {
        width: 100%;
        font-size: 13px;
        color: $orange;
        padding-bottom: 5px;
        border-bottom: 1px solid #444680;
    }
}
