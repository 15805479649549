@import "./colors";

div#resource-add-success-main-div {
    position: absolute;
    top: 0;
    height: 100vh;
    padding: 0;
    justify-content: center;
    align-content: center;

    .container {
        background-color: white;
        width: 40vw;
        min-width: 500px;
        margin: 10vh auto;
        padding: 40px;
        display: flex;
        flex-direction: column;

        .header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .check-image-div {
                width: 20%;
                margin-bottom: 20px;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .small-button {
                background-color: $success;
            }
        }

        .main-text {
            font-size: 2.5rem;
            font-weight: bold;
            line-height: 1;
            color: $darkPurple;

            span {
                color: $success;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        .details-div {
            margin-top: 25px;

            .details-list {
                margin-top: 15px;

                .detail-text {
                    font-size: 1.2rem;
                    padding: 10px;
                    color: $darkPurple;
                    background-color: $lightGrey;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;

                    &:nth-child(odd) {
                        background-color: white;
                    }

                    span {
                        font-weight: bolder;
                    }
                }
            }
        }

        .actions {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            .link {
                display: flex;
                justify-content: "center";
                text-decoration: "none";
                margin-top: 50px;
                flex: 1 1 200px;

                .link-text {
                    font-size: 1rem;
                    font-weight: bold;
                }

                .outlined-button {
                    border-color: $orange;
                    text-transform: initial;

                    .link-text {
                        color: $orange;
                    }
                }
            }
        }
    }
}
