#terms-main-div {
    .container {
        width: 80%;
        max-width: 100ch;
        margin: 0 auto;
        color: #2d2d60;

        .center-loader {
            width: 100vw;
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
