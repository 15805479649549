@import "./colors";

.subscriptions-status {
    width: 100%;
    text-align: end;

    p {
        display: flex;
        justify-content: flex-end;
        align-items: baseline;
        font-size: 1.1rem;
        color: $darkPurple;

        .status-number {
            font-size: 1.3rem;
            font-weight: bold;
            margin-left: 0.5rem;
        }

        .status-italic {
            font-style: italic;
            margin: 0 0.3rem;
        }
    }
}
