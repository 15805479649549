@import "colors";

#institution-select {
    .input-form-control {
        width: 100%;
        margin: 12px 0;

        .institution-select-label {
            font-size: 0.85rem;
            color: $darkGrey;
        }
    }
}
