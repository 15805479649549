@import "./colors";

#to-many-subs-main-div {
    width: 100vw;
    height: 100vh;
    background-color: $secondaryDarkPurple;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .info-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background-color: $darkPurple;
        width: 40vw;
        padding: 80px 50px;

        .info-text {
            color: $lightPurple;
            text-align: center;
            width: 75%;
            margin: 50px 0;
        }

        .info-solutions {
            display: flex;
            flex-direction: column;
            align-items: center;

            .solutions-text {
                color: white;
            }

            .solutions-icon {
                color: $orange;
            }
        }
    }

    .buttons-div {
        margin-top: 20px;
        width: 40vw;

        .link {
            text-decoration: none;
        }

        .button-text {
            font-family: "Open Sans", sans-serif;
            font-size: 16px;
            font-weight: bold;
        }

        .orange-button {
            .italic {
                font-style: italic;
                font-weight: normal;
            }
        }

        .white-button {
            background-color: white;
            margin-top: 20px;

            .button-text {
                color: $orange;
            }
        }
    }
}
