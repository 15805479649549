@import "./colors";
@import "./navbar";
@import "./card";
@import "./constants";

.add-permission-main-div {
    width: 50vw;
    height: 50vh;
    margin: 25vh auto;
    min-width: 450px;
    min-height: 400px;
    border-radius: 2px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;

    .header {
        width: 100%;
        height: 70px;
        padding: 0 20px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .close-button {
            padding: 10px;
            border-radius: 5px;
            background-color: $orange;

            .close-button-icon {
                color: white;
            }
        }
    }

    .content {
        padding: 20px;

        .title {
            font-size: 26px;
            font-weight: bold;
            color: $darkPurple;

            .title-span {
                color: $orange;
            }
        }

        .styled-form-control {
            width: 100%;
            margin: 20px 0;
        }
    }

    .action-button {
        .button-text {
            font-family: "Open Sans", sans-serif;
            font-size: 16px;
            font-weight: bold;
        }
        .button-icon {
            margin-left: 15px;
        }
    }
}
