@import "colors";

.loading-more-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .loading-more-text {
        margin-right: 10px;
        color: $darkGrey;
    }
}
