@import "./colors";

.navbar#navbar-clients-list {
    height: 15vh;
    flex-direction: column;

    .navbar-top {
        width: calc(100vw - 250px);
        height: 10vh;
        min-height: 95px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 3vw;
        border-bottom: 1px solid $lightGrey;
    }

    .navbar-bottom {
        width: calc(100vw - 250px);
        height: 5vh;
        min-height: 45px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 3vw;
        border-bottom: 1px solid $lightGrey;

        .search-div {
            width: 100%;
            height: 5vh;
            min-height: 45px;
            display: flex;
            align-items: center;
            padding: 0;

            .search-icon {
                margin-right: 10px;
                color: $orange;
            }

            .search-input {
                width: 100%;
                height: 5vh;
                min-height: 45px;
                font-size: 16px;
                font-family: "Open Sans", sans-serif;
                color: $darkPurple;
            }
        }
    }
}

div#clients-list-main-div {
    top: 15vh;
    min-height: 85vh;

    .card {
        .card-header {
            .green-circle {
                background-color: $success;
                border-radius: 50%;
                height: 10px;
                width: 10px;
            }
        }
    }
}

#empty-clients-list-main-div {
    top: 10vh;
    min-height: 90vh;
    background-color: white;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    .no-clients-text {
        margin-top: 10vh;
        text-align: center;
        color: $darkPurple;
        font-size: 24px;
        font-weight: bold;
        font-family: "Open Sans", sans-serif;
    }
}
