// PURPLE
$darkPurple: #2d2d60;
$disabledDarkPurple: #575780;
$secondaryDarkPurple: #232350;
$lightPurple: #7373ac;

// ORANGE
$orange: #eb5d1f;
$lightOrange: rgba(235, 93, 31, 0.06);
$orangeDisabled: rgba(235, 93, 31, 0.3);

// GREY
$lightGrey: #e8e8f2;
$darkGrey: #8a8aa1;
$disabledButtonBackground: #d8d8e6;

// WHITE
$whiteDisabled: rgba(232, 232, 242, 0.5);

// ACTIONS
$error: #f62d2d;
$success: #049e47;
