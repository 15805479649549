@import "./colors";

.tool-unavailable-modal {
    width: 30vw;
    height: 45vh;
    min-width: 500px;
    min-height: 350px;
    display: flex;
    margin: 27.5vh auto;
    justify-content: center;
    background-color: white;

    .main-div {
        width: 95%;
        height: 95%;
        display: flex;
        margin: 2.5% auto;
        flex-direction: column;
        justify-content: flex-start;

        .content {
            width: 80%;
            height: 100%;
            margin: 20px 0;
            display: flex;
            align-self: center;
            flex-direction: column;
            justify-content: space-between;

            .content-text-div {
                .modal-title {
                    font-size: 30px;
                    font-weight: bold;
                    text-align: center;
                    color: $darkPurple;
                }

                .modal-text {
                    font-size: 18px;
                    margin-top: 45px;
                    text-align: center;
                    color: $lightPurple;

                    span {
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .tool-unavailable-modal {
        width: 45vw;
    }
}

@media only screen and (max-width: 992px) {
    .tool-unavailable-modal {
        width: 55vw;
    }
}
