@import "./colors";

div#subscription-change-success-main-div {
    .container {
        width: 55%;
        min-width: 700px;
        margin: 50px auto;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;

        .success-image {
            margin-bottom: 30px;
        }

        .success-text {
            font-size: 48px;
            font-weight: bold;
            color: $darkPurple;
            text-align: center;
        }

        .email {
            font-size: 22px;
            color: $darkGrey;
            text-align: center;
        }

        .link-button {
            text-decoration: none;
            margin: 60px 0 5px 0;
        }
    }
}
