@import "./colors";

div#ambassador-code-activated-main-div {
    top: 0;
    height: 100vh;
    justify-content: center;
    align-content: center;

    .text {
        font-size: 25px;
        font-weight: bold;
        color: $darkPurple;
    }

    .container {
        align-self: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .check-image-div {
            width: 150px;
            margin-bottom: 20px;
        }

        .code-activated-text {
            max-width: 650px;
            font-size: 48px;
            font-weight: bold;
            color: $darkPurple;
            text-align: center;
        }

        .benefit-div {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 20px;

            .benefit-text {
                font-size: 22px;
                color: $darkPurple;
                text-align: center;
            }

            .discount-div {
                margin-top: 5px;
                background-color: white;
                padding: 5px 10px;

                .discount {
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: 22px;
                    color: $orange;
                }
            }
        }

        .become-ambassador-div {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 45px;

            .become-ambassador-text {
                font-size: 22px;
                color: $darkGrey;
                text-align: center;
            }
        }

        .to-ambassadors-link {
            margin-top: 60px;
            display: "flex";
            text-decoration: "none";
            justify-content: "center";
            width: 500px;
        }

        .to-projects-link {
            margin-top: 15px;
            display: "flex";
            text-decoration: "none";
            justify-content: "center";
            width: 500px;

            .to-projects-button {
                border-color: $orange;

                .to-projects-link-text {
                    color: $orange;
                    text-transform: initial;
                }
            }
        }

        .to-ambassadors-link-text,
        .to-projects-link-text {
            font-weight: bold;
        }
    }
}
