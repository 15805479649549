@import "./colors";

.sync-ical-component {
    width: 90vw;
    height: 80vh;
    display: flex;
    flex-direction: column;
    margin: 10vh auto;
    background-color: white;

    .sync-ical-main-div {
        width: 100%;
        margin: 0 auto;
        height: 100%;
        display: flex;
        justify-content: space-between;

        .sync-ical-header {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            padding: 20px;
            max-height: 10%;

            .close-modal-button {
                padding: 5px;
                color: white;
                border-radius: 0;
                background-color: $orange;
            }
        }

        .left {
            width: 70%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .content-div {
                height: 50%;
                width: 80%;
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .title {
                    color: $darkPurple;
                    font-weight: bold;
                    font-size: 24px;
                    margin-bottom: 15px;
                }

                .info-text {
                    font-size: 20px;
                    color: $darkGrey;
                }

                .input-div {
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid $lightGrey;

                    .ical-link {
                        width: 100%;
                        font-size: 18px;
                        color: $darkPurple;
                    }

                    .copy {
                        padding: 10px;

                        .icon {
                            color: $orange;
                        }
                    }
                }

                .buttons-div {
                    margin-top: 20px;
                }
            }
        }

        .right {
            width: 30%;
            display: flex;
            flex-direction: column;
            border-left: 1px solid $lightGrey;

            .content-div {
                width: 75%;
                height: 100%;
                margin: 0 auto;
                margin-top: 15%;
                display: flex;
                flex-direction: column;

                .top {
                    height: 20%;

                    .title {
                        color: $darkPurple;
                        font-weight: bold;
                        font-size: 24px;
                    }

                    .subtitle {
                        font-size: 18px;
                    }
                }

                .bottom {
                    height: 65%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;

                    .divider {
                        background-color: $orange;
                        height: 2px;
                        width: 25px;
                        margin-bottom: 10px;
                    }

                    .info-text {
                        font-size: 16px;
                        color: $darkGrey;
                    }
                }
            }
        }
    }
}
