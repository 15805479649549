@import './colors';

#circular-progress-div {
    position: absolute;
    top: 10vh;
    right: 0;
    left: calc(100vw - (100vw - 250px));
    width: 85vw;
    min-height: 90vh;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;

    .circular-progress {
        margin-bottom: 20px;
        width: 10px;
    }
}
