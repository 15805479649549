@import "./colors";
@import "./navbar";
@import "./card";
@import "./constants";

.navbar#navbar-projects-list {
    height: 15vh;
    flex-direction: column;

    .navbar-top {
        width: calc(100vw - 250px);
        height: 10vh;
        min-height: 95px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 3vw;
        border-bottom: 1px solid $lightGrey;
    }

    .navbar-bottom {
        width: calc(100vw - 250px);
        height: 5vh;
        min-height: 45px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 3vw;
        border-bottom: 1px solid $lightGrey;

        .tabs-and-search-div {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .tab {
                font-family: "Open Sans", sans-serif;
                height: 5vh;
                min-height: 45px;
            }

            .search-div {
                width: calc(100% - 340px);
                height: 5vh;
                min-height: 45px;
                display: flex;
                align-items: center;
                padding: 0;

                .search-icon {
                    margin-right: 10px;
                    color: $orange;
                }

                .search-input {
                    width: 100%;
                    height: 5vh;
                    min-height: 45px;
                    font-size: 16px;
                    font-family: "Open Sans", sans-serif;
                    color: $darkPurple;
                }
            }
        }
    }
}

div#projects-list-main-div {
    top: 15vh;
    min-height: 85vh;

    .card {
        justify-content: flex-start;

        .card-header {
            .card-title {
                max-width: calc(100% - 12px);
            }

            .green-circle {
                background-color: $success;
                border-radius: 50%;
                height: 10px;
                width: 10px;
            }
        }

        .no-hearings-text {
            text-align: center;
            color: $darkGrey;
        }
    }

    .empty-inactive-projects-list-div {
        width: 100%;
        top: 15vh;

        .no-projects-title {
            font-family: "Open Sans", sans-serif;
            text-align: center;
            font-size: 24px;
            font-weight: bold;
            color: $darkPurple;
            margin-top: 10vh;
        }
    }
}

div#empty-projects-list-main-div {
    top: 15vh;
    min-height: 85vh;
    background-color: white;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;

    .no-project-title {
        margin-top: 10vh;
        text-align: center;
        color: $darkPurple;
        font-size: 24px;
        font-weight: bold;
        font-family: "Open Sans", sans-serif;
    }

    .no-project-text {
        width: 20vw;
        margin-top: 10px;
        align-self: center;
        text-align: center;
        font-family: "Open Sans", sans-serif;
        font-size: 18px;
        color: $darkGrey;
    }
}
