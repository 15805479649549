@import "./colors";

.cards-container {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 20px);
    margin: 0 -10px;
}

.card {
    width: calc(25% - 20px);
    margin: 10px;
    min-width: 250px;
    background-color: white;
    border-radius: 3px;
    display: flex;
    flex-direction: column;

    &:hover {
        cursor: pointer;
        background-color: #f7f7fc;
    }

    .card-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid $lightGrey;
        padding: 15px 25px;
        align-items: baseline;

        .card-title {
            font-family: "Open Sans";
            font-weight: bolder;
            font-size: 20px;
            color: $darkPurple;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .texts-container {
            width: 100%;
            display: flex;
            flex-direction: column;

            .card-helper-text {
                font-family: "Open Sans";
                font-weight: bold;
                font-size: 12px;
                color: $darkPurple;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    .card-content {
        width: 90%;
        margin: 10px 0;
        align-self: center;

        .card-content-child {
            padding: 5px 15px;
            margin: 2px 0;
            display: flex;

            &:nth-child(odd) {
                background-color: $lightGrey;
            }

            .title {
                font-family: "Open Sans";
                color: $darkGrey;
                font-size: 16px;
                margin-right: 5px;
            }

            .subtitle {
                color: $darkPurple;
                font-family: "Open Sans";
                font-size: 16px;
                font-weight: 600;
            }
        }
    }
}

@media only screen and (max-width: 1680px) {
    .cards-container {
        .card {
            width: calc(25% - 20px);
        }
    }
}

@media only screen and (max-width: 1400px) {
    .cards-container {
        .card {
            width: calc(33.33% - 20px);
        }
    }
}

@media only screen and (max-width: 1110px) {
    .cards-container {
        .card {
            width: calc(50% - 20px);
        }
    }
}

@media only screen and (max-width: 850px) {
    .cards-container {
        .card {
            width: calc(100% - 20px);
        }
    }
}
