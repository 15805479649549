.avo-errors-modal {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .avo-errors-modal-content-container {
        width: 600px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        background-color: white;
        border-radius: 5px;
        padding: 1rem;

        .avo-errors-modal-message-container {
            .avo-errors-modal-message {
                font-size: 1.5rem;
            }
        }
    }
}
