@import "./colors";

.div-with-background-image {
    width: 50vw;
    height: 40vh;
    background-image: url("../images/arrows_background_small.svg");
    background-position: bottom left;
}

.promo-main-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .promo-bottom-div {
        width: 50vw;
        display: flex;
        flex-direction: column;
        padding-bottom: 25vh;

        .motto-div {
            width: 40vw;
            align-self: center;
            margin-top: 10vh;

            .motto {
                color: white;
                font-weight: bold;
                font-size: 46px;
                width: 30vw;

                span {
                    color: $orange;
                }
            }

            .divider {
                margin: 20px 0;
            }

            .promo-dowload-app-div {
                .no-account-text-div {
                    width: 100%;
                    display: flex;

                    .no-account-text {
                        color: white;
                        font-size: 16px;
                    }

                    .create-account-text {
                        color: $orange;
                        font-size: 16px;
                        font-weight: bold;
                        margin-left: 10px;
                    }
                }

                .download-links-div {
                    display: flex;
                    justify-content: space-between;
                    width: 400px;
                    margin-top: 20px;

                    .image-link {
                        display: flex;
                        width: 48%;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .promo-main-div {
        .promo-bottom-div {
            .motto-div {
                margin-top: 0;

                .motto {
                    font-size: 40px;
                }

                .promo-dowload-app-div {
                    .no-account-text-div {
                        .no-account-text,
                        .create-account-text {
                            font-size: 14px;
                        }
                    }

                    .download-links-div {
                        width: 70%;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1100px) {
    .promo-main-div {
        .promo-bottom-div {
            .motto-div {
                .promo-dowload-app-div {
                    .no-account-text-div {
                        .no-account-text {
                            font-size: 18px;
                        }

                        .create-account-text {
                            font-size: 16px;
                        }
                    }

                    .download-links-div {
                        width: 80%;

                        .image-link {
                            img {
                                max-height: 5rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 950px) {
    .promo-main-div {
        .promo-bottom-div {
            .motto-div {
                .promo-dowload-app-div {
                    .no-account-text-div {
                        .no-account-text,
                        .create-account-text {
                            font-size: 16px;
                        }
                    }

                    .download-links-div {
                        width: 100%;

                        .image-link {
                            width: 48%;
                        }
                    }
                }
            }
        }
    }
}
