@import "./colors";
@import "./navbar";
@import "./card";
@import "./constants";

.project-add-client-main-div {
    width: 65vw;
    height: 60vh;
    margin: 15vh auto;
    border-radius: 2px;
    background-color: white;
    display: flex;
    justify-content: space-between;

    .left,
    .right {
        .header {
            width: 100%;
            height: 15%;
        }

        .content {
            width: 80%;
            height: 85%;
            margin: 0 auto;
            padding: 10px 20px;

            .top-div {
                height: 75% !important;
                border: 1px solid transparent;

                .title {
                    width: 70%;
                    margin-top: 50px;
                    font-family: "Open Sans", sans-serif;
                    font-size: 26px;
                    font-weight: bold;
                    color: $darkPurple;

                    .title-span {
                        color: $orange;
                    }
                }
            }
        }
    }

    .left {
        width: 60%;

        .content {
            .top-div {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .styled-form-control {
                    width: 100%;
                    margin: 20px 0;
                }

                .no-data-text {
                    width: 100%;
                    margin-top: 5px;
                    text-align: center;
                    font-family: "Open Sans", sans-serif;
                    color: $darkGrey;
                    font-size: 16px;
                }
            }
        }
    }

    .right {
        width: 40%;
        background-color: $lightGrey;

        .header {
            padding: 0 20px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .close-button {
                padding: 10px;
                border-radius: 5px;
                background-color: $orange;

                .close-button-icon {
                    color: white;
                }
            }
        }

        .content {
            .top-div {
                .subtitle {
                    font-family: "Open Sans", sans-serif;
                    font-size: 16px;
                    color: $darkPurple;
                }
            }

            .action-button {
                background-color: $darkPurple;
            }
        }
    }

    .action-button {
        .button-text {
            font-family: "Open Sans", sans-serif;
            font-size: 16px;
            font-weight: bold;
        }
        .button-icon {
            margin-left: 15px;
        }
    }
}
