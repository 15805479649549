@import "colors";

.subscription-active-status-pill {
    padding: 0 0.5rem;
    border-radius: 1rem;
    max-width: 60px;

    &.active {
        background-color: $success;
    }

    &.inactive {
        background-color: $error;
    }

    .status-text {
        color: white;
        font-size: 12px;
        font-weight: bolder;
        text-align: center;
    }
}
